<template>
  <button type="button" class="btn btn-secondary p-2 text-dark ml-2 px-3 py-2 rounded" :title="buttonText" @click="doSomething()">
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  name: "project-request-to-bid-button",
  props: {
    projectId: { type: String, required: true },
    isUserBidding: { type: Boolean, default: () => false }
  },
  data: function() {
    return {
      isBidding: false
    };
  },
  watch: {
    'isUserBidding': function () {
      this.isBidding = this.isUserBidding;
    }
  },
  computed: {
    buttonText() {
      return (!this.isBidding ? "I'm Interested" : "Remove Me from List");
    }
  },
  methods: {
    doSomething () {
      !this.isBidding ? this.$emit('add-bidder') : this.$emit('remove-bidder');
    }
  },
  mounted() {
    this.isBidding = this.isUserBidding;
  }
};
</script>
