<template>
  <fragment>
    <div class="existing-list">
      <div v-if="!isPageLoading">
        <div class="form-group" v-if="lists.length > 0">
          <label for="ExistingList">Please select an existing list:</label>
          <select class="form-control" id="ExistingList" @change="existingListSelected" v-model="selectedList">
            <option v-for="list in lists" :key="list.id" :value="list.id">{{ list.name }}</option>
          </select>
        </div>
        <div v-else>
          Sorry, you have not yet set up any invitation lists.  Please choose another selection option.  You may save your subsequent selections to a list for future selection.
        </div>
      </div>
      <loading v-else :active.sync="isDataLoading" :is-full-page="false"></loading>

      <div v-if="(selectedList || '' != '')">
        <CompanyListing :setCompanyOptions="setCompanyOptions" :searchResults="searchResults" :getSearchResults="getSearchResults" :isDataLoading="isDataLoading" />
      </div>
    </div>
  </fragment>
</template>

<style lang="scss" scoped>
  .existing-list {
    padding-top: 2rem
  }
</style>

<script>
  import { mapActions } from "vuex";
  import ShowError from "@/utils/errorMessage";
  import CompanyListing from "@/views/project/components/invite-2-bid/company-listing.vue";

  export default {
    name: "existing-list",
    components: {
      CompanyListing
    },
    props: {
      setCompanyOptions: { type: Function }
    },
    data: function () {
      return {
        selectedList: null,
        lists: [],
        isPageLoading: true,
        isDataLoading: false,
        showSidebar: true,
        searchCriteria: {},
        searchResults: { total: 0, results: [] }
      };
    },
    mounted: function () {
      this.setLists();
    },

    methods: {
      ...mapActions([
        "fetchBidInvitationListsForLoggedInUser"
      ]),
      setLists() {
        this.isDataLoading = true;

        Promise.all([
          this.fetchBidInvitationListsForLoggedInUser()
        ])
          .then(result => {
            this.lists = result[0];
            this.isPageLoading = false;
            this.isDataLoading = false;
            this.checkTest();
          })
          .catch(ShowError.bind(this));
      },
      existingListSelected(event) {
        this.setSelectedList(event.target.value);
      },
      setSelectedList() {
        this.getSearchResults();
      },
      getSearchResults(sortColumn) {
        let companies = this.lists.find(l => l.id == this.selectedList).invited;

        if (sortColumn) {
          companies = companies.sort((cat1, cat2) => {
            let value1 = (sortColumn.sortOrder ? (cat1[sortColumn.sortOrder] || '') : cat1['companyName']).toLowerCase();
            let value2 = (sortColumn.sortOrder ? (cat2[sortColumn.sortOrder] || '') : cat2['companyName']).toLowerCase();

            if (value1 < value2) {
              return sortColumn.sortDirection === "asc" ? -1 : 1;
            }
            else if (value1 > value2) {
              return sortColumn.sortDirection === "asc" ? 1 : -1;
            }
            else {
              return 0;
            }
          });
        }

        this.searchResults.total = companies.length;
        this.searchResults.results = companies;
      },
      addToList() {
        this.$props.setCompanyOptions(this.searchResults.results.filter(result => this.selectedRecords.filter(selectedRecord => selectedRecord == result.id).length > 0));
      },
      checkTest() {
        if (new URLSearchParams(window.location.search).has('test')) {
          this.setSelectedList(this.lists[0].id);
          this.selectedRecords = this.selectedList.bidInvitationListCompanies.map(bic => bic.company.id);
          this.addToList();
        }
      }
    }
  };
</script>
