<template>
  <fragment>
    <div>
      Your message has been sent to the selected <strong>{{ selectedCompanies.length }} companies/users</strong>.  You will receive an email when a contact responds.  You can also view response statuses by visiting the Project Details Page.
    </div>

    <div class="view-project-details-button text-center">
      <button class="btn btn-success" @click="closeInvite2Bid">View Project Details Page</button>
    </div>
  </fragment>
</template>

<style lang="scss" scoped>
  .view-project-details-button {
    padding-top: 2rem;
  }
</style>

<script>

  export default {
    name: "confirmed",
    components: {
    },
    mixins: [],
    data: function () {
      return {

      };
    },
    props: {
      selectedCompanies: { type: Array },
      changeToInvitationsTab: { Type: Function }
    },
    methods: {
      async onSubmit() {
        return;
      },
      closeInvite2Bid() {
        this.changeToInvitationsTab();
        this.$bvModal.hide("bv-modal-invite-2-bid");
      }
    }
  };
</script>
