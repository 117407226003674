<template>
  <fragment>
    <div class="form-group">
      <label for="cc">
        <strong>CC</strong>
      </label>
      <input
        type="text"
        class="form-control"
        id="cc"
        name="cc"
        v-model="localEmail.cc"
        data-vv-as="CC"
        @input="emailUpdated"
      />
    </div>

    <div class="form-group">
      <label for="bcc">
        <strong>BCC</strong>
      </label>
      <input
        type="text"
        class="form-control"
        id="bcc"
        name="bcc"
        v-model="localEmail.bcc"
        data-vv-as="BCC"
        @input="emailUpdated"
      />
    </div>

    <div class="form-group">
      <label for="subject">
        <strong>Subject</strong>
        <span class="red-dot ml-3"></span>
      </label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': errors.has('subject') }"
        id="subject"
        name="subject"
        autocomplete="off"
        v-validate="'required'"
        v-model="localEmail.subject"
        data-vv-as="Subject"
        @input="emailUpdated"
      />
      <span class="invalid-feedback" v-if="errors.has('subject')">The Subject field is required</span>
    </div>

    <div class="form-group">
      <label for="body">
        <strong>Body</strong>
        <span class="red-dot ml-3"></span>
      </label>
      <div class="placeholder-field-message">
        The body of the email message has a number of placeholder fields which will be replaced with project-specific or company-specific details when you preview the email next. These fields are:
        <ul>
          <li :key="placeholderField.field" v-for="placeholderField in placeholderFields">
            {{placeholderField.field}} - {{placeholderField.description}}
          </li>
        </ul>
      </div>

      <app-editor
        id="body"
        name="body"
        class="w-100"
        :class="{ 'input-validation-error is-invalid': errors.has('body') }"
        v-model="localEmail.body"
        @input="bodyUpdated"
        data-vv-as="Body"
        autocomplete="off"
        v-validate="'required'"
      ></app-editor>
      <span class="invalid-feedback" v-if="errors.has('body')">The Body field is required</span>
    </div>

    <div class="container compose-email-footer">
      <div class="row">
        <div class="col">
          <a href="#" @click.prevent="closeModal">Go back</a>
        </div>
        <div class="col text-right">
          <button class="btn btn-success" @click="previewEmail">Preview Email</button>
        </div>
      </div>
    </div>
  </fragment>
</template>

<style lang="scss" scoped>
.placeholder-field-message {
  margin-top: 0.5rem;
}

.placeholder-field-message ul {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>

<script>
import AppEditor from "@/components/app-editor";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { mapGetters } from "vuex";

export default {
  name: "compose",
  components: {
    AppEditor
  },
  computed: {
    ...mapGetters(["getProject"])
  },
  props: {
    email: { type: Object },
    template: { type: String }
  },
  data: function() {
    return {
      emailTemplate: "",
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "undo",
            "redo",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList"
          ]
        }
      },
      placeholderFields: [
        { field: "{{ProjectName}}", description: "Project Name" }
      ],
      localEmail: {}
    };
  },
  beforeMount: function() {
    this.localEmail = this.email;
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("bv-modal-follow-up-email");
    },
    async previewEmail() {
      var isFormValid = await this.$validator.validate();
      if (!isFormValid) {
        return;
      }

      this.$emit("goToNextStage");
    },
    emailUpdated() {
      this.$emit("updateEmail", this.localEmail);
    },
    bodyUpdated() {
      this.$emit("updateEmail", this.localEmail);
    }
  }
};
</script>
