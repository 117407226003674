const STORAGE_KEY_INVITE2BID_COMPANY_TABLE_SELECTED_COLUMNS = 'invite2bid-company-table-selected-columns';
const STORAGE_KEY_INVITE2BID_COMPANY_TABLE_COLUMN_SORTING = 'invite2bid-company-table-column-sorting';
const STORAGE_KEY_INVITE2BID_COMPANY_TABLE_PAGE_SIZE = 'invite2bid-company-table-page-size';

let columns = [
  {
    display: "Company Name",
    field: "companyName",
    sortable: true,
    filterable: true
  },
  {
    display: "Contact Name",
    field: "contactName",
    sortable: true
  },
  {
    display: "Title",
    field: "title"
  },
  {
    display: "Email",
    field: "email"
  },
  {
    display: "City/Town",
    field: "city",
    sortable: true,
    filterable: true
  },
  {
    display: "Province",
    field: "province",
    sortable: true,
    filterable: true
  },
  { ignore: true }
]

export {
  STORAGE_KEY_INVITE2BID_COMPANY_TABLE_SELECTED_COLUMNS,
  STORAGE_KEY_INVITE2BID_COMPANY_TABLE_COLUMN_SORTING,
  STORAGE_KEY_INVITE2BID_COMPANY_TABLE_PAGE_SIZE,
  columns
}
