<template>
  <fragment>
    <loading :active.sync="isDataLoading" :is-full-page="false"></loading>
    <div v-if="getProject.allowDocumentsView">
      <form v-on:submit.prevent="onSubmit">
        <div class="row form-inline">
          <div class="col-md-4">
            <h3>Documents</h3>
          </div>
          <div class="col-md-4 text-center">
            <div class="ld ld-ring ld-spin" v-if="this.keywordCount==null && this.documentList === false"></div>
            <span v-if="this.keywordCount!==null">{{this.keywordCount}} keyword hit(s) found</span>
          </div>
          <div class="col-md-4 text-right d-print-none" v-if="showDocumentSearch">
              <input id="keywordSearch" name="keywordSearch" type="text" class="form-control" :class="{ 'is-invalid': errors.has('keywordSearch') }"
                autocomplete="off" placeholder="Keyword Search" v-model="keywordSearch" v-validate="'required'" data-vv-as="keywordSearch">
              <button type="submit" class="btn btn-primary" title="Search"><i class="fab fa-searchengin"></i> Search</button>
              <button v-if="showDocumentSearch && !documentList" class="btn btn-danger" title="Cancel Search" @click="cancelSearch()"><i class="far fa-window-close"></i> Cancel</button>
          </div>
        </div>
      </form>

      <div class="project-info-content">
        <div v-if="documentList">
          <info-documents-list :rootFolder="rootFolder"></info-documents-list>
        </div>
        <div v-else>
          <keyword-documentsearch-result :keywordSearch="keywordSearch" :rootFolder="rootFolder"></keyword-documentsearch-result>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="alert alert-warning d-flex align-items-center d-print-none">
        <i class="fas fa-exclamation-triangle mr-3"></i>
        <span v-html="messageAccessDenied"></span>
      </div>
    </div>
  </fragment>
</template>

<style lang="scss" scoped>

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.row {
  padding: .75rem;
}

.list-group-item-heading {
  font-weight: bold;
  border-top: $table-border-width solid $table-border-color;
}

.list-group,
.list-group-item {
  padding: 0;
}

.list-group.list-group-root .list-group-item {
  border-radius: 0;
  border-width: 1px 0 0 0;
}

.keywordInput {
  width: 300px;
}

</style>

<script>
import { mapGetters } from "vuex";
import { FolderService } from "@/services/";
import InfoDocumentsList from "./info-documents-list";
import KeywordDocumentSearchResult from "./keyword-documentsearch-result";
import { DocumentSearchService } from "@/services/";

export default {
  components: {
    'info-documents-list': InfoDocumentsList,
    'keyword-documentsearch-result': KeywordDocumentSearchResult
  },
  name: "project-info-documents",
  data: function() {
    return {
      keywordSearch: null,
      documentList: true,
      isDataLoading: true,
      isDownloading: false,
      messageAccessDenied: process.env.VUE_APP_TEMPLATE_PROJECT_INFO_ACCESS_DENIED,
      rootFolder: null,
      keywordCount: null
    };
  },
  computed: {
    ...mapGetters([
      "getProject",
      "getUseDocumentSearch"
    ]),
    showDocumentSearch() {
        return (this.getUseDocumentSearch || '').toUpperCase() === 'TRUE'
    },
  },
  methods: {
    async onSubmit() {
      this.documentList = true;
      var isFormValid = await this.$validator.validate();
      if (!isFormValid) {
        return;
      }

      this.documentList = false;
      this.keywordCount = null;
      await DocumentSearchService.getKeywordDensityByProjectId(this.keywordSearch, this.getProject.id).then(response => {
        if(response.status === 200) {
          this.keywordCount = response.data.keywordDensityTotal;
        } else if (response.status === 200) {
          this.keywordCount = 0;
        }
      })
      .catch( error => {
        throw(error);
      });
    },
    cancelSearch() {
      this.documentList = true;
      this.keywordSearch = null;
      this.keywordCount = null;
    }
  },
  mounted() {
    FolderService.getDisplayStructure(this.getProject.rootFolderId).then(response => {
      this.rootFolder = response.data;
      this.isDataLoading = false;
    });
  }
};
</script>
