<template>
  <fragment>
    <div v-if="!isEmailSending">
      <strong>Subject:</strong> {{ bidInvitation.bidInvitationEmail.subject }}
      <div class="email-preview">
        <p>{{getGreeting()}}</p>
        <span v-html="preview"></span>
        <p>
          <a href="javascript:void(0)" class="view-project-link">View Project</a>
        </p>

        <div class="container response-buttons">
          <div class="row">
            <div class="col">
              <button class="btn btn-success">Yes, we will submit a quote.</button>
            </div>
            <div class="col">
              <button class="btn btn-secondary">No, we will not submit a quote.</button>
            </div>
          </div>
        </div>
      </div>
      <div class="container compose-email-footer">
        <div class="row">
          <div class="col ">
            <a href="javascript:void()" @click="goBack">Go back</a>
          </div>
          <div class="col text-right">
            <button class="btn btn-success" @click="sendEmail">Send Email</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div>
        Sending bid invitations to the selected companies...
      </div>
      <div>
        <loading :active.sync="isEmailSending" :is-full-page="false"></loading>
      </div>
    </div>
  </fragment>
</template>

<style lang="scss" scoped>
  .email-preview {
    border: 2px solid black;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 10rem;
  }

  .response-buttons {
    margin-top: 3rem;
  }

  .view-project-link {
    text-decoration:underline;
  }
</style>

<script>
  import { mapGetters, mapActions } from "vuex";
  import ShowError from "@/utils/errorMessage";
  import { getUserCompanyName } from "@/utils/currentUserInfo";

  export default {
    name: "preview",
  components: {
    },

  mixins: [],
  data: function() {
    return {
      preview: "",
      isEmailSending: false
    };
  },
   props: {
     selectedCompanies: { type: Array },
     selectionTypeId: { type: String },
     bidInvitation: { type: Object }
    },
    mounted: function () {
      this.createPreview();
    },
    computed:
    {
      ...mapGetters(["getProject"]),
    },
    methods: {
      async onSubmit() {
        return;
    },
     async sendEmail() {
       var isFormValid = await this.$validator.validate();

        if (!isFormValid) {
          return;
        }

       this.isEmailSending = true;

       this.saveProjectBidInvitation(this.bidInvitation).then(() => this.$emit("goToNextStage"))
         .catch(() => {
           this.isEmailSending = false;
           ShowError.call(this);
         });
      },
      goBack() {
          this.$emit("goToPreviousStage");
      },
      createPreview() {
          let preview = this.bidInvitation.bidInvitationEmail.body;

          preview = preview.replace("{{ProjectOwnerName}}", getUserCompanyName());
          preview = preview.replace("{{ProjectPurpose}}", this.getProject.title);
          preview = preview.replace("for {{TradeType}}", this.getTradeTypeValue());

          let closingDate = new Date(this.getProject.closingDate);
          preview = preview.replace("{{ClosingDate}}", closingDate.toDateString());
          preview = preview.replace("{{ClosingTime}}", closingDate.toLocaleTimeString());

          this.preview = preview;
      },
      getTradeTypeValue()
      {
        return (this.bidInvitation.bidInvitationEmail.tradeType || '').length > 0 ? `for ${this.bidInvitation.bidInvitationEmail.tradeType}` : '';
      },
      getGreeting() {
        let companyWithUsers = this.selectedCompanies.find(c => c.users && c.users.length > 0);

        if (companyWithUsers) {
          return `Dear ${companyWithUsers.users[0].user.firstName} ${companyWithUsers.users[0].user.lastName}:`;
        }

        return '';
      },
      ...mapActions(["saveProjectBidInvitation"])
  }
};
</script>
