<template>
  <fragment>
      <div v-if="getProject.allowProjectDetailsIdentificationView">
        <h3>Project Identification</h3>
        <div class="row" v-if="getProject.ocaaNumber">
          <div class="col-md-4">Project Number</div>
          <div class="col-md-8">{{ getProject.ocaaNumber }}</div>
        </div>
        <div class="row" v-if="getProject.lcaNumber">
          <div class="col-md-4">LCA Number</div>
          <div class="col-md-8">{{ getProject.lcaNumber }}</div>
        </div>
        <div class="row" v-if="getProject.belongZone">
          <div class="col-md-4">Project Zone Location</div>
          <div class="col-md-8">{{ getProject.belongZone }}</div>
        </div>
        <div class="row" v-if="getProject.title">
          <div class="col-md-4">Project Title</div>
          <div class="col-md-8">{{ getProject.title }}</div>
        </div>
        <div class="row" v-if="getProject.ownerSolicitationNumber">
          <div class="col-md-4">Owner Solicitation Number</div>
          <div class="col-md-8">{{ getProject.ownerSolicitationNumber }}</div>
        </div>
      </div>

      <div v-if="getProject.allowProjectDetailsDetailsView">
      <hr />
        <h3>Project Details</h3>
        <div class="row" v-if="getProject.addresses.length">
          <div class="col-md-4">Site Addresses</div>
          <div class="col-md-8">
            <div class="row" v-for="(address, index) in getProject.addresses" :key="'site-address-' + index">
              <div class="col">{{ getAddressDisplay(address) }}</div>
            </div>
          </div>
        </div>
        <div class="row" v-if="getProject.closingDate">
          <div class="col-md-4">Closing Date</div>
          <div class="col-md-8">{{ getProject.closingDate | datetime('eeee, MMMM dd yyyy, h:mm aa') }} {{ getProject.timeZone.code }}</div>
        </div>
        <div class="row" v-if="getProject.addenda">
          <div class="col-md-4">Number of Addenda</div>
          <div class="col-md-8">{{ getProject.addenda }}</div>
        </div>
        <div class="row" v-if="getProject.addendaInfo">
          <div class="col-md-4">Addenda Notes</div>
          <div class="col-md-8" v-html="displayAddendaInfo"></div>
        </div>
        <div class="row" v-if="getProject.submitBidsTo">
          <div class="col-md-4">Submit Bids To</div>
          <div class="col-md-8 preserved-content">
            <a v-if="isUrlSubmitBidsTo" :href="getProject.submitBidsTo | url" rel="noopener" target="_blank">{{ getProject.submitBidsTo }}</a>
            <span v-if="!isUrlSubmitBidsTo">{{ getProject.submitBidsTo }}</span>
          </div>
        </div>
        <div class="row" v-if="getProject.obtainBidDocuments">
          <div class="col-md-4">Obtain Bid Documents</div>
          <div class="col-md-8 preserved-content">
            <a v-if="isUrlObtainBidDocuments" :href="getProject.obtainBidDocuments | url" rel="noopener" target="_blank">{{ getProject.obtainBidDocuments }}</a>
            <span v-if="!isUrlObtainBidDocuments">{{ getProject.obtainBidDocuments }}</span>
          </div>
        </div>
        <div class="row" v-if="getProject.otherClosingDate">
          <div class="col-md-4">Other Closing Date</div>
          <div class="col-md-8">{{ getProject.otherClosingDate | datetime('eeee, MMMM dd yyyy, h:mm aa') }} {{ getProject.timeZone.code }}</div>
        </div>
        <div class="row" v-if="getProject.bonding">
          <div class="col-md-4">Bonding</div>
          <div class="col-md-8">{{ getProject.bonding }}</div>
        </div>
        <div class="row mt-1" v-if="orderedSiteMeeting.length">
          <div class="col-md-4">Site Meetings</div>
          <div class="col-md-8">
            <ul class="p-0">
              <li class="mb-2" v-for="(meeting, index) in orderedSiteMeeting" :key="'site-meeting-' + index">
                <div class="row">
                  <div class="col">{{ meeting.date | datetime('eeee, MMMM dd yyyy, h:mm aa') }} {{ getProject.timeZone.code }} - {{ meeting.priority | priority }}</div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="preserved-content">{{ meeting ? meeting.text : '' }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-if="getProject.allowProjectDetailsDescriptionView">
      <hr />
        <h3>Project Description</h3>
        <div class="row" v-if="getProject.tenderStage">
          <div class="col-md-4">Tender Stage</div>
          <div class="col-md-8">{{ getProject.tenderStage }}</div>
        </div>
        <div class="row" v-if="getProject.funding">
          <div class="col-md-4">Funding</div>
          <div class="col-md-8">{{ getProject.funding }}</div>
        </div>
        <div class="row" v-if="getProject.procurementType">
          <div class="col-md-4">Procurement Type</div>
          <div class="col-md-8">{{ getProject.procurementType }}</div>
        </div>
        <div class="row" v-if="getProject.classificationType">
          <div class="col-md-4">Classification Type</div>
          <div class="col-md-8">{{ getClassificationTypeDisplay }}</div>
        </div>
        <div class="row" v-if="getProject.ownerType">
          <div class="col-md-4">Owner Type</div>
          <div class="col-md-8">{{ getProject.ownerType }}</div>
        </div>
        <div class="row" v-if="getProject.budget">
          <div class="col-md-4">Project Budget</div>
          <div class="col-md-8">{{ getProject.budget | currency }}</div>
        </div>
        <div class="row" v-if="getProject.scopeOfWork">
          <div class="col-md-4">Scope of Work</div>
          <div class="col-md-8 preserved-content" v-html="getProject.scopeOfWork"></div>
        </div>
        <div class="row" v-if="getProject.comments">
          <div class="col-md-4">Comments</div>
          <div class="col-md-8 preserved-content" v-html="getProject.comments"></div>
        </div>
        <div class="row" v-if="getProject.disclaimer">
          <div class="col-md-4">Disclaimer</div>
          <div class="col-md-8 preserved-content" v-html="getProject.disclaimer"></div>
        </div>
        <div class="row" v-if="getProject.tradeAgreements && getProject.tradeAgreements.length">
          <div class="col-md-4">Applicable Trade Agreements</div>
          <div class="col-md-8">{{ getProject.tradeAgreements.join(", ") }}</div>
        </div>
      </div>

      <div v-if="!hasProjectDetailsWithPermissions && !isPreBid">
        <div class="alert alert-warning d-flex align-items-center d-print-none">
          <i class="fas fa-exclamation-triangle mr-3"></i>
          <span v-html="messageAccessDenied"></span>
        </div>
      </div>

      <template v-if="isPreBid">

      <hr />
      <h3>Pre-Bid Notes</h3>
      <div class="row mb-2" v-for="(note, index) in getProject.prebidNotes" :key="'prebid-note-' + index">
        <div class="col-md-4">
          <span> {{ note.name }} </span> <br />
          <span> {{ note.createdOn | datetime('MMM dd, yyyy p') }} </span>
        </div>
        <div class="col-md-8 preserved-content" v-html="note.text"></div>
      </div>

      </template>
  </fragment>
</template>

<style scoped>
.preserved-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}
</style>

<script>
import { mapGetters } from "vuex";
import AddressDisplayMixin from "@/mixins/addressDisplay";

const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

export default {
  name: "project-info-identification",
  mixins: [AddressDisplayMixin],
  data: function () {
    return {
      messageAccessDenied: process.env.VUE_APP_TEMPLATE_PROJECT_INFO_ACCESS_DENIED
    }
  },
  computed: {
    displayAddendaInfo () {
      return this.getProject.addendaInfo.replace(/\n/gi, '<br />');
    },
    hasProjectDetailsWithPermissions() {
      return (this.getProject.allowProjectDetailsIdentificationView || this.getProject.allowProjectDetailsDetailsView || this.getProject.allowProjectDetailsDescriptionView)
    },
    getClassificationTypeDisplay() {
      let value = '';
      if (this.getProject.classificationType) {
        value += this.getProject.classificationType;

        if (this.getProject.classificationOfWorks) {
          value += ': ';
          value += this.getProject.classificationOfWorks.join(', ');
        }
      }
      return value;
    },
    isPreBid () {
      return this.getProject.tenderStage === "Prebid";
    },
    isUrlSubmitBidsTo () {
      return !!urlPattern.test(this.getProject.submitBidsTo);
    },
    isUrlObtainBidDocuments () {
      return !!urlPattern.test(this.getProject.obtainBidDocuments);
    },
    orderedSiteMeeting() {
      let meetings = this.getProject.siteMeetings || [];
      return meetings.sort((a, b) => new Date(a.date) - new Date(b.date));
    },
    ...mapGetters([
      "getProject",
    ])
  },
  filters: {
    priority: function (value) {
      if (!value) return 'Optional';

      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }
  }
};
</script>
