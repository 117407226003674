<template>
  <fragment>
    <div v-if="rootFolder && rootFolder.children && !rootFolder.children.length" class="alert alert-warning mt-3">
      No documents available
    </div>
    <div v-else-if="!(rootFolder && rootFolder.children && !rootFolder.children.length) && !selectedFiles.length" class="alert alert-warning mt-3">
      Please select files and/or folders to download.
    </div>

    <div v-if="rootFolder && rootFolder.children && rootFolder.children.length" class="list-group list-group-root well" style="margin-top: 10px;">
      <div class="hidden-xs list-group-item-heading">
        <div class="row">
          <div class="col-sm-7">File Name</div>
          <div class="col-sm-2">Uploaded On</div>
          <div class="col-sm-2">Document Type</div>
          <div class="col-sm-1"> &nbsp; </div>
        </div>
      </div>

      <template v-if="rootFolder">
        <info-documents-item v-for="(node, index) in rootFolder.children" :key="index"
                             :nodes="node.children" :target="node" :downloadFn="downloadFile" :selectedFileIds.sync="selectedFiles"
                             :selectedFilesSize.sync="selectedFilesSizeTotal" :depth="0">
        </info-documents-item>
      </template>
    </div>

    <div class="mt-2 d-print-none">
        <button v-if="isFilesAvailable" type="button" class="btn btn-success mr-2" :class="{ 'running' : isDownloading }" :disabled="isDownloading"
        @click="downloadSelectedFiles()"> Download </button>
        <button v-if="isFilesAvailable" type="button" class="btn btn-success ld-ext-right" :class="{ 'running' : isDownloading }" :disabled="isDownloading" @click="downloadAll()">
            <span v-if="!isDownloading"> Download All Documents </span>
            <span v-if="isDownloading"> Downloading -- Please wait </span>
            <div class="ld ld-ring ld-spin"></div>
        </button>
    </div>
    <b-modal id="bv-modal-download-file" size="l" no-close-on-esc hide-header hide-footer :no-close-on-backdrop="true">
      <DownloadFile :data="currentDownload" :key="downloadFileKey"></DownloadFile>
    </b-modal>
  </fragment>
</template>

<style lang="scss" scoped>
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/mixins";

  .row {
    padding: .75rem;
  }

  .list-group-item-heading {
    font-weight: bold;
    border-top: $table-border-width solid $table-border-color;
  }

  .list-group,
  .list-group-item {
    padding: 0;
  }

    .list-group.list-group-root .list-group-item {
      border-radius: 0;
      border-width: 1px 0 0 0;
    }

  .keywordInput {
    width: 300px;
  }
</style>

<script>
  import { mapGetters } from "vuex";
  import { ApplicationActivityService } from "@/services/";
  import InfoDocumentsItem from "@/views/project/components/info-documents-item.vue";
  import DownloadFile from "@/views/project/components/download-file.vue";
  import Toast from "@/utils/toast";

  export default {
    name: "project-info-documents",
    props: ["rootFolder"],
    components: {
      'info-documents-item': InfoDocumentsItem,
      DownloadFile
    },
    data: function () {
      return {
        downloadFileKey: true,
        isDownloading: false,
        currentDownload: {},
        selectedFiles: [],
        selectedFilesSizeTotal: []
      };
    },
    computed: {
      isFilesAvailable() {
        if (this.rootFolder && this.rootFolder.children && this.rootFolder.children.length) {
          return this.scanFiles(this.rootFolder.children)
        }
        return false;
      },
      fileSizeTotal() {
        return this.getFolderFileSizeTotal(this.rootFolder);
      },
      selectedFileSizeTotal() {
        return this.getSelectedFilesSize(this.selectedFilesSizeTotal)
      },
      ...mapGetters(["getProject"])
    },
    methods: {
      scanFiles(files) {
        let currentLevelHasFiles = files.find(f => f.type !== 'Folder');
        let flatChildren = files.flatMap(r => r.children);

        if (currentLevelHasFiles)
          return true;

        if (!flatChildren.length)
          return false;

        return this.scanFiles(flatChildren);
      },
      async onSubmit() {
        var isFormValid = await this.$validator.validate();
        if (!isFormValid) {
          return;
        }
      },
      downloadAll() {
        ApplicationActivityService.create(this.getDownloadAllFilesActivity())

        this.currentDownload = {
          downloadType: "all",
          filename: "project-files.zip",
          fileSize: this.fileSizeTotal
        };

        this.openDownloadFileModal();
      },
      downloadSelectedFiles() {
        if (!this.selectedFiles.length) {
          Toast.danger(this,"Please select files and/or folders to download.");
        } else {
          this.currentDownload = {
            downloadType: "selected",
            filename: "project-files.zip",
            selectedFiles: this.selectedFiles,
            fileSize: this.selectedFileSizeTotal
          };

          this.openDownloadFileModal();
        }
      },
      downloadFile(fileId, filename, fileType, fileSize) {
        this.currentDownload = {
          downloadType: 'single',
          fileId: fileId,
          filename: filename,
          fileType: fileType,
          fileSize: fileSize
        };

        this.openDownloadFileModal();
      },
      getDownloadAllFilesActivity() {
        return {
          Product: "Link2Build",
          Action: "DownloadAllFiles",
          ProductSubArea: "Project",
          ProductSubAreaId: this.$route.params.id
        };
      },
      openDownloadFileModal() {
        this.$bvModal.show("bv-modal-download-file");
      },
      getFolderFileSizeTotal(folder) {
        let totalSize = 0;

        if (folder.children) {
          for (let child of folder.children) {
            totalSize += (child.type === "Folder" ? this.getFolderFileSizeTotal(child) : parseInt(child.size));
          }
        }

        return totalSize;
      },
      getSelectedFilesSize(selected) {
        let totalSelectedSize = 0;

        for (let fileSize of selected) {
          totalSelectedSize += parseInt(fileSize);
        }

        return totalSelectedSize;
      }
    },
  };
</script>
