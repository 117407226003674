<template>
  <fragment>
    <form v-on:submit.prevent="onSubmit">
      <div class="modal-header">
        <h3 class="modal-title">Download File - {{data.filename}}{{data.fileType}}</h3>
        <a class="close-button" href="javascript:void(0)" @click="closeModal">X</a>
      </div>

      <div class="modal-body">
        <div class="container">
          <div class="form-row">
            <div class="col text-center">
              <vue-circle :progress="0"
                          :size="200"
                          :reverse="false"
                          line-cap="round"
                          :fill="fill"
                          empty-fill="rgba(0, 0, 0, .1)"
                          :animation-start-value="0.0"
                          :start-angle="0"
                          insert-mode="append"
                          :thickness="5"
                          :show-percent="true"
                          ref="downloadProgressCircle">
                <p>{{circleText}}</p>
              </vue-circle>

              <div class="mt-3">
                {{sizeDownloaded}}{{fileSizeType}} / {{totalDownloaded}}{{fileSizeType}} completed
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </fragment>
</template>

<script>
  import axios from 'axios'
  import {  mapGetters } from "vuex";
  import { FileService, FolderService } from "@/services/";
  import FileDownload from "@/utils/fileDownload";
  import VueCircle from 'vue2-circle-progress'
  import { EventBus } from "@/event-bus/event-bus.js";
  import Toast from "@/utils/toast";

  const KBinBytes = 1024;
  const MBinBytes = 1024 * 1024;

  export default {
    name: "download-files",
    components: {
      VueCircle
    },
    props:
    {
      data: { type: Object }
    },
    data: function () {
      return {
        downloaded: 0,
        percentCompleted: 0,
        cancelled: false,
        cancelRequest: null,
        circleText: "Downloading...",
        fill: { gradient: ["green"] }
      };
    },
    computed: {
      fileSizeType() {
        return this.data.fileSize >= MBinBytes ? "MB" : "KB";
      },
      fileSizeScale() {
        return this.fileSizeType == "MB" ? MBinBytes : KBinBytes;
      },
      sizeDownloaded() {
        return this.numberWithCommas(Math.round(this.downloaded / this.fileSizeScale));
      },
      totalDownloaded() {
        return this.numberWithCommas(Math.round(this.data.fileSize / this.fileSizeScale));
      },
      cancelToken() {
        const CancelToken = axios.CancelToken;

        let downloadFileComponent = this;

        return new CancelToken(function executor(c) { downloadFileComponent.cancelRequest = c; });
      },
      ...mapGetters(["getProject"])
    },
    mounted: function () {
      EventBus.$on('api-call-progress', this.onDownloadProgress);
      this.startDownload();
    },
    methods: {
      startDownload() {
        if (this.data.downloadType === 'single') {
          this.downloadSingle();
        } else if (this.data.downloadType === 'selected') {
          this.downloadSelected();
        }
        else {
          this.downloadAll();
        }
      },
      closeModal() {
        this.cancelRequest && this.cancelRequest();
        this.cancelled = true;

        this.$bvModal.hide("bv-modal-download-file");
      },
      downloadSingle() {
        FileService.downloadFile(this.data.fileId, this.cancelToken).then(response => {
          FileDownload(`${this.data.filename}${this.data.fileType}`, response.data);
          this.setCompleteStatus();
          
        }).catch(this.downloadError);
      },
      downloadSelected() {
        return FolderService.downloadSelectedFiles(this.getProject.rootFolderId, this.data.selectedFiles, this.cancelToken).then(response => {
          FileDownload(this.data.filename, response.data);
          this.setCompleteStatus();
        }).catch(this.downloadError);
      },
      downloadAll() {
        return FolderService.download(this.getProject.rootFolderId, this.cancelToken).then(response => {
          FileDownload(this.data.filename, response.data);
          this.setCompleteStatus();
        }).catch(this.downloadError);
      },
      setCompleteStatus() {
        this.$refs.downloadProgressCircle.updateProgress(100);
        this.downloaded = this.data.fileSize;
        this.circleText = "Complete!"

        //Give it a second to let the update of the progress circle catch up!
        setTimeout(() =>
        {
          this.closeModal();
        }, 2000);
      },
      onDownloadProgress(progressEvent) {
        this.downloaded = progressEvent.loaded;
        this.percentCompleted = Math.round((this.downloaded / parseInt(this.data.fileSize)) * 100);

        if (this.percentCompleted % 2 == 0 && this.$refs.downloadProgressCircle) {
          this.$refs.downloadProgressCircle.updateProgress(this.percentCompleted);
        }
      },
      numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      downloadError() {
        if (!this.cancelled)
        {
          Toast.danger(this,"Sorry! There was an error attempting to download that file.");
          this.closeModal();
        }
      }
    }
  };
</script>
