<template>
  <fragment>
    <div class="existing-list">
      <div v-if="!isPageLoading">
        <div class="form-group" v-if="projects.length > 0">
          <p>
            A number of projects that were previously bid on have been found.
          </p>
          <label for="ExistingProject">Please select a previous project:</label>
          <select class="form-control" id="ExistingProject" @change="setSelectedProject" v-model="selectedProject">
            <option v-for="project in projects" :key="project.id" :value="project.id">{{ project.ocaaNumber }} - {{ project.title }}</option>
          </select>
        </div>
        <div v-else>
          Sorry, no previously bid-on projects created by you were found.  Please choose another selection option.
        </div>
      </div>
      <loading v-else :active.sync="isPageLoading" :is-full-page="false"></loading>

      <div v-if="(selectedProject || '' != '')">
        <CompanyListing :setCompanyOptions="setCompanyOptions"
                        :searchResults="searchResults"
                        :getSearchResults="getSearchResults"
                        :isDataLoading="isDataLoading">
        </CompanyListing>
      </div>
    </div>
  </fragment>
</template>

<style lang="scss" scoped>
  .existing-list {
    padding-top: 2rem
  }
</style>

<script>
  import { mapActions, mapGetters } from "vuex";
  import ShowError from "@/utils/errorMessage";
  import CompanyListing from "@/views/project/components/invite-2-bid/company-listing.vue";

  export default {
    name: "previous-projects",
    components: {
      CompanyListing
    },
    props: {
      setCompanyOptions: { type: Function }
    },
    data: function () {
      return {
        selectedProject: null,
        isDataLoading: false,
        isPageLoading: true,
        showSidebar: true,
        searchCriteria: {},
        searchResults: { total: 0, results: [] },
        projects: []
      };
    },
    mounted: function () {
        this.fetchProjectsForLoggedInUser().then((result) => {
        this.projects = result;
        this.isPageLoading = false;
      }).catch(ShowError.bind(this));
    },
    methods: {
      ...mapActions([
        "fetchProjectsForLoggedInUser"
      ]),
      ...mapGetters([
        "getProject",
        "getProjectsForLoggedInUser"
      ]),
      addToList() {
        this.$props.setCompanyOptions(this.searchResults.results.filter(result => this.selectedRecords.filter(selectedRecord => selectedRecord == result.id).length > 0));
      },
      existingProjectSelected(event) {
        this.setSelectedProject(event.target.value);
      },
      setSelectedProject() {
        this.getSearchResults();
      },
      getSearchResults(sortColumn) {
        let companies = this.projects.find(l => l.id == this.selectedProject).bidders;

        if (sortColumn) {
          companies = companies.sort((cat1, cat2) => {
            let value1 = (sortColumn.sortOrder ? (cat1[sortColumn.sortOrder] || '') : cat1['companyName']).toLowerCase();
            let value2 = (sortColumn.sortOrder ? (cat2[sortColumn.sortOrder] || '') : cat2['companyName']).toLowerCase();

            if (value1 < value2) {
              return sortColumn.sortDirection === "asc" ? -1 : 1;
            }
            else if (value1 > value2) {
              return sortColumn.sortDirection === "asc" ? 1 : -1;
            }
            else {
              return 0;
            }
          });
        }

        this.searchResults.total = companies.length;
        this.searchResults.results = companies;
      },
      getSelectedCompanies() {
        return this.selectedProject == null ? [] : this.selectedProject.bids.map(bid => bid.company);
      }
    }
  };
</script>
