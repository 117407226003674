<template>
  <fragment>
    <form v-on:submit.prevent="onSubmit">
      <div class="modal-header">
        <h5 class="modal-title">Compose Invite to Bid Email</h5>

        <button type="button" class="close" aria-label="Close" @click="closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <Compose v-if="isComposeStage()" @goToNextStage="setStageToPreview" @updateEmail="emailUpdated" :email="bidInvitation.bidInvitationEmail" :template="bidInvitation.bidInvitationEmail.body"></Compose>
        <Preview v-if="isPreviewStage()" @goToNextStage="setStageToConfirmed" @goToPreviousStage="setStageToCompose" :selectedCompanies="selectedCompanies" :bidInvitation="bidInvitation"></Preview>
        <Confirmed :changeToInvitationsTab="changeToInvitationsTab" v-if="isConfirmedStage()" :selectedCompanies="selectedCompanies"></Confirmed>
      </div>
    </form>
  </fragment>
</template>

<script>
  import Compose from "@/views/project/components/invite-2-bid/email/compose.vue";
  import Preview from "@/views/project/components/invite-2-bid/email/preview.vue";
  import Confirmed from "@/views/project/components/invite-2-bid/email/confirmed.vue";
  import { Invite2BidEmailStages } from "@/utils/enums";
  import { getUserCompanyName } from "@/utils/currentUserInfo";
  import { mapGetters } from "vuex";

export default {
    name: "email",
    components: {
      Compose,
      Preview,
      Confirmed
    },
    mixins: [],
    computed:
    {
      ...mapGetters(["getProject", "getUser"])
    },
    props: {
      selectedCompanies: { type: Array },
      selectionTypeId: { type: String },
      changeToInvitationsTab: { Type: Function }
    },
    data: function() {
      return {
        stage: null,
        bidInvitation:
        {
            projectId: "",
            selectionTypeId: "",
            bidInvitationEmail: {
              to: "",
              cc: "",
              bcc: "",
              subject: "",
              tradeType: "",
              body: "",
              from: 'theteam@bidcentral.ca'
            },
            bidInvitationCompanies: []
        }
      };
    },
    mounted: function () {
        this.bidInvitation.BidInvitationInvitees = this.selectedCompanies;

        this.bidInvitation.bidInvitationEmail.body = this.getTemplate();
        this.bidInvitation.bidInvitationEmail.subject = `Invitation to bid for ${this.getProject.title}`;
        this.bidInvitation.selectionTypeId = this.selectionTypeId;
        this.bidInvitation.projectId = this.getProject.id;

        this.stage = Invite2BidEmailStages.COMPOSE;
    },
    methods: {
    async onSubmit() {
        return;
    },
    async previewEmail() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

    },
    emailUpdated(email) {
      this.bidInvitation.bidInvitationEmail = email;
    },
    isComposeStage() {
      return this.stage === Invite2BidEmailStages.COMPOSE;
    },
    isPreviewStage() {
      return this.stage === Invite2BidEmailStages.PREVIEW;
    },
    isConfirmedStage() {
      return this.stage === Invite2BidEmailStages.CONFIRMED;
    },
    setStageToCompose() {
      this.stage = Invite2BidEmailStages.COMPOSE;
    },
    setStageToPreview() {
      this.stage = Invite2BidEmailStages.PREVIEW;
    },
    setStageToConfirmed() {
      this.stage = Invite2BidEmailStages.CONFIRMED;
    },
    getTemplate() {
      let template = '<p>{{ProjectOwnerName}} is inviting you to participate as a subcontractor for {{TradeType}} for {{ProjectName}} closing on {{ClosingDate}} at {{ClosingTime}}. &nbsp;Please let us know if you are interested in submitting a quote to us.</p>';

      template = template.replace("{{ProjectOwnerName}}", getUserCompanyName());
      template = template.replace("{{ProjectName}}", `${this.getProject.ocaaNumber} ${this.getProject.title}`);

      let closingDate = new Date(this.getProject.closingDate);
      template = template.replace("{{ClosingDate}}", closingDate.toDateString());
      template = template.replace("{{ClosingTime}}", closingDate.toLocaleTimeString());

      return template;
      },
    closeModal() {
      this.$bvModal.hide("bv-modal-invite-2-bid-email");
    },
  }
};
</script>
