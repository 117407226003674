<template>
  <fragment>
    <div class="existing-list">
      <div v-if="!isPageLoading">
        <div class="search-controls">
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="CompanyName">Company Name</label>
                <input class="form-control w-100 rounded p-1 px-2"
                       id="CompanyName"
                       name="CompanyName"
                       placeholder="Search by company name"
                       autocomplete="off"
                       type="text"
                       v-model="searchCriteria.companyName" />

              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="category">Service Region</label>
                <v-select id="serviceRegion" name="serviceRegion" class="v-select-multi" style="width: 100%" multiple
                          label="name" :placeholder="'All'" :reduce="cow => cow.id" :options="serviceRegions"
                          v-model="searchCriteria.serviceRegionIds" :closeOnSelect="false">
                </v-select>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="category">Category</label>
                <treeselect id="category" name="category" v-model="searchCriteria.categoryIds" :searchable="false" :multiple="true" :options="categories"
                  :load-options="loadOptions" search-nested :flat="true" :cacheOptions="false" />
              </div>
            </div>
          </div>

          <div class="form-row" v-if="advancedSearch">
            <div class="col">
              <div class="form-group">
                <label for="category">Zone</label>
                <v-select id="zone" name="zone" class="v-select-multi" style="width: 100%" multiple label="name" :placeholder="'All'"
                          :reduce="zone => zone.id" :options="getZones" v-model="searchCriteria.zoneIds" :closeOnSelect="false">
                </v-select>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="category">Business Category</label>
                <v-select id="businessCategories" name="businessCategories" class="v-select-multi" style="width: 100%" multiple
                          label="name" :placeholder="'All'" :reduce="category => category.id" :options="getBusinessCategories"
                          v-model="searchCriteria.businessCategoryIds" :closeOnSelect="false">
                </v-select>
              </div>
            </div>
          </div>
          <div class="form-row" v-if="advancedSearch">
            <div class="col">
              <div class="form-group">
                <label for="category">Labour Type</label>
                <div class="row">
                  <div class="col-12">
                    <label class="control2 control-radio">
                      All
                      <input id="labourTypeId" name="labourTypeId" type="radio" :value="null" v-model="searchCriteria.labourTypeId" checked="checked" />
                      <span class="control_indicator2"></span>
                    </label>
                  </div>

                  <div class="col-12" v-for="labourType in getLabourTypes" :key="labourType.id">
                    <label class="control2 control-radio">
                      {{ labourType.name }}
                      <input type="radio" name="labourTypeId" :value="labourType.id" v-model="searchCriteria.labourTypeId" @change="clearLabourOfWorks" />
                      <div class="control_indicator2"></div>
                    </label>
                  </div>

                  <div class="row">
                    <div class="col mb-3">
                      <div id="company-labour-of-works-container" v-if="searchCriteria.labourTypeId && getLabourOfWork.length > 0">
                        <label class="control control-checkbox" v-for="work in getLabourOfWork" :key="work.id">
                          {{ work.name }}
                          <input type="checkbox" name="labourOfWorks" :value="work.id" v-model="searchCriteria.labourOfWorkIds" />
                          <div class="control_indicator"></div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row" v-if="advancedSearch">
            <div class="col">
              <div class="form-group">
                <label for="category">Classification of Work</label>
                <v-select id="classificationOfWork" name="classificationOfWork" class="v-select-multi" style="width: 100%" multiple
                          label="name" :placeholder="'All'" :reduce="cow => cow.id" :options="getClassificationOfWork"
                          v-model="searchCriteria.classificationOfWorkIds" :closeOnSelect="false">
                </v-select>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col search-links">
              <a v-if="!advancedSearch" href="javascript:void(0)" @click="toggleSearchType">Advanced Search</a>
              <a v-if="advancedSearch" href="javascript:void(0)" @click="toggleSearchType">Basic Search</a>
            </div>
          </div>

          <div class="search-buttons">
            <div class="container">
              <div class="form-row  align-items-center">
                <div class="col-xs-8">
                  <button class="btn-primary btn-lg" @click="getSearchResults">Search</button>
                </div>
                <div class="col-xs-3">
                  <a href="javascript:void(0)" @click="clearSearch">Clear All</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="searchSubmitted" class="results-table">
        <CompanyListing :setCompanyOptions="setCompanyOptions"
                        :searchResults="searchResults"
                        :getSearchResults="getSearchResults"
                        :isLocalData="false"
                        :isDataLoading="isDataLoading"></CompanyListing>
      </div>
      <loading v-else :active.sync="isDataLoading" :is-full-page="false"></loading>

    </div>
  </fragment>
</template>

<style lang="scss" scoped>
  .search-controls {
    padding-top: 2rem
  }

  .search-buttons {
    padding-top: 2rem
  }

    .search-buttons .col-xs-8 {
      padding-right: 2rem;
    }

  .results-table {
    padding-top: 4rem;
  }

  .search-links {
    margin-top: 1rem;
    text-decoration:underline;
  }
</style>

<script>
  import { mapActions, mapGetters } from "vuex";
  import ShowError from "@/utils/errorMessage";
  import { alpha as alphaSort } from "@/utils/sort";
  import { CategoryService, CompanyService } from "@/services/";
  import CompanyListing from "@/views/project/components/invite-2-bid/company-listing.vue";
  import Treeselect from "@riophae/vue-treeselect";
  import Toast from "@/utils/toast";

  import "@riophae/vue-treeselect/dist/vue-treeselect.css";

  const defaultSearchCriteria = {
    page: 1,
    pageSize: 10,
    companyName: null,
    labourTypeId: null,
    labourOfWorkIds: [],
    serviceRegionIds: [],
    hasContactEmail: true
  };

  export default {
    name: "search",
    components: {
      CompanyListing,
      Treeselect
    },
    props: {
      setCompanyOptions: { type: Function }
    },
    computed: {
      getLabourOfWork: function () {
        if (this.searchCriteria.labourTypeId) {
          let selectedLabourType = this.getLabourTypes.find(type => type.id === this.searchCriteria.labourTypeId);
          if (selectedLabourType) {
            return selectedLabourType.labourOfWorks;
          }
        }
        return [];
      },
      ...mapGetters([
        "getZones",
        "getMembershipRoles",
        "getBusinessCategories",
        "getClassificationOfWork",
        "getLabourTypes"
      ]),
    },
    beforeMount() {
      this.searchCriteria = { ...defaultSearchCriteria };
    },
    data: function () {
      return {
        isPageLoading: true,
        isDataLoading: false,
        searchResults: { total: 0, results: [] },
        searchCriteria: {},
        categories: [],
        serviceRegions: [],
        searchSubmitted: false,
        advancedSearch: false
      };
    },
    mounted: function () {
      Promise.all([
        CategoryService.getTopLevel(),
        this.fetchServiceRegions(),
        this.fetchZones(),
        this.fetchClassificationOfWork(),
        this.fetchBusinessCategories(),
        this.fetchLabourTypes(),
        this.fetchRoles()
      ])
        .then(result => {
          this.categories = result[0].data.map(i => this.normalizeCategoryData(i)).sort((a, b) => alphaSort(a.label, b.label));
          this.serviceRegions = result[1];
          this.isPageLoading = false;
        })
        .catch(ShowError.bind(this));
    },
    methods: {
      ...mapActions([
        "fetchCategories",
        "fetchServiceRegions",
        "fetchZones",
        "fetchClassificationOfWork",
        "fetchBusinessCategories",
        "fetchLabourTypes",
        "fetchRoles"
      ]),
      clearLabourOfWorks() {
        this.searchCriteria.labourOfWorkIds = [];
      },
      clearSearch() {
        this.searchCriteria = { ...defaultSearchCriteria };
        this.selectedRecords = [];
        this.searchResults.total = 0;
        this.searchResults.results = [];
        this.searchSubmitted = false;
      },
      getSelectedCompanies() {
        return this.selectedList == null ? [] : this.selectedList.bidInvitationListCompanies.map(bic => bic.company);
      },
      addToList() {
        this.$props.setCompanyOptions(this.searchResults.results.filter(result => this.selectedRecords.filter(selectedRecord => selectedRecord == result.id).length > 0));
      },
      getSearchResults(sortColumn, paging) {
        if (!this.isPageLoading) {
          this.isDataLoading = true;
        }

        if (sortColumn) {
          this.searchCriteria.sortDirection = sortColumn.sortDirection;
          this.searchCriteria.sortOrder = sortColumn.sortOrder;
        }

        if (paging) {
          this.searchCriteria.pageSize = paging.pageSize;
          this.searchCriteria.page = paging.page;
        }

        let activeSearchCriteria = { ...this.searchCriteria };

        if (!this.advancedSearch) {
          activeSearchCriteria.zoneIds = [];
          activeSearchCriteria.classificationOfWorkIds = [];
          activeSearchCriteria.businessCategoryIds = [];
          activeSearchCriteria.membershipIds = [];
        }

        return CompanyService.getUsers(activeSearchCriteria)
          .then(response => {
            this.searchResults = response.data;
            this.isDataLoading = false;
            this.searchSubmitted = true;
          })
          .catch(() => {
            this.isDataLoading = false;
            Toast.danger(this, "Oops! An error has occured.")
          });
      },
      async loadOptions({ action, parentNode, callback }) {
        if (action === 'LOAD_CHILDREN_OPTIONS') {
          let childData = await CategoryService.getLevelByParentId(parentNode.id);
          parentNode.children = childData.data.map(i => this.normalizeCategoryData(i));
          callback();
        }
      },
      normalizeCategoryData(node) {
        let category = { id: node.id, label: node.code + ' - ' + node.name };
        if (node.hasChildren) {
          category.children = node.children.map(i => this.normalizeCategoryData(i)).sort((a, b) => alphaSort(a.label, b.label));
        }
        return category;
      },
      toggleSearchType() {
        this.advancedSearch = !this.advancedSearch;
      }
    }
  };
</script>
