<template>
  <form v-on:submit.prevent="onSubmit">
    <div class="modal-header">
      <h4 class="modal-title">Save List</h4>
    </div>

    <div class="modal-body">
      <div class="form-group">
        <label for="bidInvitationListName">List Name <span class="red-dot ml-3"></span></label>
        <input id="bidInvitationListName" name="bidInvitationListName" type="text" class="form-control" :class="{ 'is-invalid': errors.has('bidInvitationListName') }"
               autocomplete="off" placeholder="Name"
               v-model="bidInvitationList.Name"
               v-validate="'required|unique'"
               data-vv-as="Name" />
        <span v-show="errors.has('bidInvitationListName')" class="invalid-feedback">{{ errors.first('bidInvitationListName') }}</span>
      </div>
    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-success">Save</button>
      <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
    </div>
  </form>
</template>

<script>
  import { mapActions } from "vuex";
  import Toast from "@/utils/toast";
  import { BidInvitationListService } from "@/services/";
  import { Validator } from 'vee-validate';

  export default {
    name: 'save-list',
    props: {
      selectedCompanies: { type: Array },
    },
    data: function () {
      return {
        isEdit: false,
        bidInvitationList: {}
      }
    },
    beforeMount: function () {
      this.setupCustomValidation();
    },
    mounted: function () {
      this.bidInvitationList = {
        Name: null,
        Invitees: this.selectedCompanies.map(c => ({ id: c.id, type: c.type }))
      };
    },
    methods: {
      async onSubmit() {
        var isFormValid = await this.$validator.validate();

        if (!isFormValid) {
          return;
        }

        BidInvitationListService.save(this.bidInvitationList)
          .then(() => {
            Toast.success(this, "Successfully saved company list!");
            this.$bvModal.hide("bv-modal-invite-2-bid-save-list");
            this.closeModal();
          })
          .catch(() => {
            Toast.danger(this, "Oops! There was an error.");
            this.closeModal();
          });
      },
      closeModal() {
        this.$bvModal.hide("bv-modal-invite-2-bid-save-list");
      },
      setupCustomValidation() {
        const isUnique = () =>
          new Promise(resolve => {
              BidInvitationListService.listNameExists(this.bidInvitationList.Name).then(response => {
                if (!response.data) {
                  return resolve({
                    valid: true
                  });
                }
                else {
                  return resolve({
                    valid: false,
                    data: {
                      message: `Sorry, that name is already taken.`
                    }
                  });
                }
              });
          });

        Validator.extend("unique", {
          validate: isUnique,
          getMessage: (field, params, data) => data.message
        });
      },
      ...mapActions(["saveBidInvitationList"])
    }
  }
</script>
