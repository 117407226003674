<template>
  <fragment>
    <div class="main_page">
      <app-navbar></app-navbar>
      <div class="page">
        <loading :active.sync="isPageLoading" is-full-page></loading>
        <div>
          <div class="page-title">
            <h1>Project Information Sheet</h1>
          </div>

          <div class="page-content" v-if="!isPageLoading">
            <div class="d-flex flex-wrap align-items-center justify-content-between d-print-none">
              <router-link :to="{ name: 'project-search', query: $route.params.qs }" class="btn btn-link"><i class="fa fa-chevron-left mr-2"></i>Back to Project List</router-link>

              <menu class="btn-group">
                <app-share-link></app-share-link>

                <request-to-bid-button :projectId="getProject.id" v-if="getProject.enableRequestToBid && getProject.allowView && getProject.allowUserRequestToBid" :isUserBidding.sync="isUserBidding" @add-bidder="requestToBid" @remove-bidder="removeBidder"></request-to-bid-button>

                <follow-button v-if="getProject.allowView"
                               class="btn btn-success d-flex align-items-center"
                               :projectId="getProject.id"
                               :projectTitle="getProject.title"
                               v-model="isUserFollowing">

                  <template v-slot:is-following>
                    <i class="far fa-check-circle"></i>
                    <span class="ml-2">Following</span>
                  </template>

                  <template v-slot:is-not-following>
                    <img src="@/assets/rss.svg" />
                    <span class="ml-2">Follow</span>
                  </template>
                </follow-button>

                <a v-if="getProject.allowView && getProject.registerUrl" :href="getProjectRegisterUrl" class="btn bg-warning" target="_blank">
                  <i class="far fa-bell"></i>
                  <span class="ml-2">Register</span>
                </a>

                <router-link v-if="$permissions().hasEditProjectPermission || ($permissions().hasAddPrivateProjectPermission && getProject.isPrivate)" class="btn btn-info" :to="'/project/edit/' + getProject.id" title="Edit Project">
                  <i class="far fa-edit"></i>
                  <span class="ml-2">Edit Project</span>
                </router-link>

                <button v-if="showInvite2Bid" type="button" class="btn btn-light" @click="openInviteToBidModal">
                  <i class="fas fa-envelope"></i>
                  <span class="ml-2">Invite to Bid</span>
                </button>

                <button class="btn btn-light" type="button" onclick="window.print();"><i class="fa fa-print mr-2"></i> Print Page</button>
              </menu>
            </div>

            <div class="d-flex flex-wrap align-items-end mb-3 bd-highlight">
              <div>
                <h2>{{ getProject.title }}</h2>
                <p class="mb-0">Closing Date: {{ getProject.closingDate || 'N/A' | datetime('eeee, MMMM dd yyyy, h:mm aa') }} {{ getProject.timeZone.code }}</p>
              </div>

              <div class="ml-auto bd-highlight d-print-none">
                <router-link :to="'/project/purchase/' + getProject.id" class="btn btn-link" v-if="showPurchaseLink && !getProject.allowView">
                  <i class="fa fa-credit-card mr-w"></i> Purchase Project
                </router-link>

                <button type="button" class="btn btn-link" v-if="useOrderPrints" @click="orderPrint(getProject.id)">
                  <i class="fas fa-box mr-2"></i>Order Print
                </button>

                <button v-if="this.getProject.allowUserViewActivityReport" type="button" class="btn btn-link" @click="downloadActivityReport()">
                  <i class="fas fa-download"></i>
                  Download Activity Report
                </button>
              </div>
            </div>

            <div class="d-flex mb-3 flex-md-nowrap flex-wrap tab-nav d-print-none">
              <ul class="nav nav-tabs" id="projectTabs" role="tablist">
                <li id="detailTab" class="nav-item">
                  <a href="#projectDetails" data-toggle="tab" class="nav-link active">
                    <img src="@/assets/details.svg" class="mr-3 mr-xl-4" alt="" />
                    Project Details
                  </a>
                </li>
                <li id="contactsTab" class="nav-item">
                  <a href="#projectContacts" data-toggle="tab" class="nav-link">
                    <img src="@/assets/people-dark.svg" class="mr-3 mr-xl-4" alt="" />
                    Project Contacts
                  </a>
                </li>
                <li id="documentsTab" class="nav-item">
                  <a href="#projectDocumentsAddenda" data-toggle="tab" class="nav-link">
                    <img src="@/assets/document-dark.svg" class="mr-3 mr-xl-4" alt="" />
                    Documents & Addenda
                  </a>
                </li>
                <li id="biddersTab" class="nav-item">
                  <a href="#projectBidders" data-toggle="tab" class="nav-link">
                    <img src="@/assets/bidders.svg" class="mr-3 mr-xl-4" alt="" />
                    Bidders
                  </a>
                </li>
                <li id="invitationTab" class="nav-item" v-if="showInvite2Bid">
                  <a href="#invitations" data-toggle="tab" class="nav-link">
                    <img src="@/assets/finger.svg" class="mr-3 mr-xl-4" alt="" />
                    My Invitations
                  </a>
                </li>
              </ul>
            </div>

            <div class="tab-content">
              <div id="projectDetails" class="tab-pane active"> <InfoInformation /></div>
              <div id="projectContacts" class="tab-pane fade"><InfoContacts /></div>
              <div id="projectDocumentsAddenda" class="tab-pane fade"><InfoDocuments /></div>
              <div id="projectBidders" class="tab-pane fade"><InfoBidders /></div>
              <div id="invitations" class="tab-pane fade" v-if="showInvite2Bid"><InfoInvitations @openITB="openInviteToBidModal" /></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="bv-modal-request-to-bid" size="lg" hide-header hide-footer :no-close-on-backdrop="true" :no-enforce-focus="true">
      <project-request-to-bid @user-is-bidding="updateBiddingFlags"></project-request-to-bid>
    </b-modal>

    <b-modal id="bv-modal-invite-2-bid" size="xl" hide-header hide-footer :no-close-on-backdrop="true">
      <Invite2Bid :changeToInvitationsTab="changeToInvitationsTab"></Invite2Bid>
    </b-modal>

  </fragment>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import { ProjectService, ProjectBidService, ApplicationActivityService } from "@/services/";
  import Toast from "@/utils/toast";
  import MessageBox from "@/utils/messagebox";
  import FileDownload from "@/utils/fileDownload";

  import AppNavBar from "@/components/app-navbar.vue";
  import InfoInformation from "@/views/project/components/info-identification.vue";
  import InfoContacts from "@/views/project/components/info-contacts.vue";
  import InfoDocuments from "@/views/project/components/info-documents.vue";
  import InfoBidders from "@/views/project/components/info-bidders.vue";
  import InfoInvitations from "@/views/project/components/info-invitations.vue";
  import Invite2Bid from "@/views/project/components/invite-2-bid/invite-2-bid.vue";
  import ProjectFollowButton from "@/views/project/components/project-follow-button.vue";
  import ProjectRequestToBidButton from "@/views/project/components/project-request-to-bid-button.vue";

  const TabEnum = {
    Details: "DETAILS",
    Contacts: "CONTACTS",
    Documents: "DOCUMENTS",
    Bidders: "BIDDERS",
    Invitations: "INVITATIONS"
  };

  export default {
    components: {
      "app-navbar": AppNavBar,
      InfoInformation,
      InfoContacts,
      InfoDocuments,
      InfoBidders,
      InfoInvitations,
      Invite2Bid,
      "follow-button": ProjectFollowButton,
      'app-share-link': () => import("@/components/app-share-link.vue"),
      'project-request-to-bid': () => import("@/views/project/components/project-request-to-bid.vue"),
      'request-to-bid-button': ProjectRequestToBidButton
    },
    data: function () {
      return {
        hasCompanies: false,
        isPageLoading: true,
        isUserFollowing: false,
        isUserBidding: false,
        bidRequestCompanyId: null,
        useOrderPrints: false
      };
    },
    computed: {
      getProjectRegisterUrl() {
        return (
          (this.getProject.registerUrl.indexOf("://") === -1 ? "//" : "") +
          this.getProject.registerUrl
        );
      },
      showPurchaseLink() {
        return (this.getUseProjectPayment || '').toUpperCase() === 'TRUE' && this.hasCompanies
      },
      showInvite2Bid() {
        return (this.getUseInvite2Bid || '').toUpperCase() === 'TRUE' && this.$permissions().hasSendBidInvitations;
      },
      TabEnum: () => TabEnum,
      ...mapGetters(["getProject", "getUseProjectPayment", "getCurrentUser", "getUseInvite2Bid", "getUseOrderPrints"])
    },
    methods: {
      getActivityReportFilename() {
        let formattedDate = new Date(Date.now()).toLocaleString().split(',')[0];

        return `${this.getProject.title} Activity Report ${formattedDate}.xlsx`;
      },
      async downloadActivityReport() {
        this.isPageLoading = true;

        try {
          return ProjectService.activityReport(this.getProject.id).then(response => {
            FileDownload(this.getActivityReportFilename(), response.data);
            this.isPageLoading = false;
          })
          .catch(() => {
            this.isPageLoading = false;
            Toast.danger(this, "Oops! An error has occured.")
          });
        }
        catch (err) {
          this.isPageLoading = false;
        }
      },
      async orderPrint(id) {
        let message = this.$createElement('span', {}, [
          'Are you sure you want to order prints from your local association?',
          this.$createElement('br'),
          this.$createElement('br'),
          'We will contact you shortly after your request to complete the request.'
        ]);

        let confirm = await MessageBox.confirm(this, "Order Print", message, { okTitle: "Yes, order prints", cancelTitle: "No, don't order prints" });

        if (confirm) {
          ProjectService.orderPrint(id)
            .then(() => Toast.success(this, "Your prints have been ordered."))
            .catch(() => Toast.danger(this, "Oops! An error has occured."));
        }
      },
      async removeBidder() {
        let message = this.$createElement('span', {}, [
          'Are you sure you want to remove yourself from the list?',
          this.$createElement('br'),
          this.$createElement('br'),
          'If you are following this project, you will still remain on the follower list and continue to receive notifications. To remove yourself from the follower list, please choose to unfollow the project.'
        ]);

        let confirm = await MessageBox.confirm(this, "Remove from List", message, { size: 'lg', okTitle: 'Yes, remove me', cancelTitle: 'No, do not remove me' });

        if (confirm) {
          var request = await ProjectBidService.removeFromBid({ projectId: this.$route.params.id, companyId: this.bidRequestCompanyId });
          this.setProjectBids(request.data);
          this.isUserBidding = false;
          Toast.success(this, "You have been removed from the list.");
        }
      },
      requestToBid() {
        this.$bvModal.show("bv-modal-request-to-bid");
      },
      updateBiddingFlags(model) {
        this.isUserFollowing = model.isFollowing;
        this.isUserBidding = model.isBidding;
      },
      openInviteToBidModal() {
        this.$bvModal.show("bv-modal-invite-2-bid");
      },
      changeToInvitationsTab() {
        this.fetchProjectDetails(this.$route.params.id).then(() => {
          // TODO: remove this
          $('#invitationTab > a').tab('show'); // eslint-disable-line
        });
      },
      ...mapActions([
        "fetchProjectDetails",
        "fetchClientSettings",
        "fetchUser",
        "setProjectBids"
      ]),
      getViewDetailsActivity() {
        return {
          Product: "Link2Build",
          Action: "ViewProjectDetails",
          ActionTargetId: this.$route.params.id
        };
      }
    },
    mounted() {
      Promise.all([
        this.fetchUser(this.getCurrentUser.id),
        this.fetchProjectDetails(this.$route.params.id),
        this.fetchClientSettings(),
        ApplicationActivityService.create(this.getViewDetailsActivity())
      ])
        .then(result => {
          this.hasCompanies = result[0].companies.length;
          this.isPageLoading = false;
          this.isUserFollowing = this.getProject.isUserFollowing;
          this.isUserBidding = this.getProject.isUserBidding
          this.bidRequestCompanyId = result[0].companies.length ? result[0].companies[0].id : null;
          this.useOrderPrints = (this.getUseOrderPrints || '').toUpperCase() === 'TRUE';
        })
        .catch(err => {
          if (err && err.response && err.response.data && err.response.data.message) {
            let message = this.$createElement('span', {}, ['Oops! You managed to get to an area you do not have permission to visit', this.$createElement('br'), 'Redirecting back to dashboard']);
            Toast.danger(this, [message], { autoHideDelay: 7500 });

            this.$router.push('/dashboard');
            return;
          }

          this.isPageLoading = false;
          let message = this.$createElement('span', {}, ['Oops! An error has occured and some data could not be loaded', this.$createElement('br'), 'Please contact an administrator']);
          Toast.danger(this, [message], { autoHideDelay: 7500 });
        });
    }
  };
</script>
