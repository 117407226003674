import Toast from "@/utils/toast";

export const showError = function (err) {
    if (err && err.response && err.response.data && err.response.data.message && err.response.data.message === 'The request is invalid.') {
        let message = this.$createElement('span', {}, ['Oops! You managed to get to an area you do not have permission to visit', this.$createElement('br'), 'Redirecting back to dashboard']);
        Toast.danger(this, [message], { autoHideDelay: 7500 });

        this.$router.push('/dashboard');
        return;
    }

    this.isPageLoading = false;
    let message = this.$createElement('span', {}, ['Oops! An error has occurred', this.$createElement('br'), 'Please contact an administrator']);
    Toast.danger(this, [message], { autoHideDelay: 7500 });
}

export default showError
