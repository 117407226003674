<template>
    <fragment>
      <loading :active.sync="isDataLoading" :is-full-page="false"></loading>
      <div v-if="highlights === null && !isDataLoading" class="alert alert-warning mt-3" >
        No documents matches the search criteria.
      </div>
      <div v-else class="list-group list-group-root well" style="margin-top: 10px;">
        <div class="hidden-xs list-group-item-heading">
          <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-7">File Name</div>
            <div class="col-sm-3">Document Type</div>
            <div class="col-sm-1"></div>
          </div>
        </div>

        <template v-if="files">
          <keyword-highlight-file-item v-for="(file, index) in filesHighlightsMerged" :key="index" :file="file"></keyword-highlight-file-item>
        </template>
      </div>
    </fragment>
</template>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
.row {
  padding: .75rem;
}
.list-group-item-heading {
  font-weight: bold;
  border-top: $table-border-width solid $table-border-color;
}
.list-group,
.list-group-item {
  padding: 0;
}
.list-group.list-group-root .list-group-item {
  border-radius: 0;
  border-width: 1px 0 0 0;
}
</style>

<script>
import KeywordHighlightFileItem from "./keyword-highlight-file-item";
import { mapGetters } from "vuex";
import { DocumentSearchService } from "@/services/";
export default {
  name: "keyword-documentsearch-result",
  props: ["keywordSearch", "rootFolder"],
  components: {
    'keyword-highlight-file-item': KeywordHighlightFileItem,
  },
  data: function() {
    return {
      files: [],
      isDataLoading: true,
      highlights: null,
      filesHighlightsMerged: []
    };
  },
  computed: {
    ...mapGetters(["getProject"])
  },
  methods: {
    async setFilesArray(array){
      if (array.children !== undefined && array.children.length > 0){
        array.children.map(e => {
          if (e.children !== undefined && e.children.length > 0){
            this.setFilesArray(e);
          } else {
            this.files.push({id: e.id, name: e.name, type: e.type, highlights: null});
          }
        });
      } else {
        this.files.push({id: array.id, name: array.name, type: array.type, highlights: null});
      }
    }
  },
  async mounted() {
    await DocumentSearchService.getKeywordHighlightsByProjectId(this.keywordSearch, this.getProject.id).then(async response => {
      if (response.status === 200){
        this.highlights = response.data;

        await this.setFilesArray(this.rootFolder);

        this.highlights.map(h => {
          this.files.map(f => {
            if (f.id === h.documentId){
              this.filesHighlightsMerged.push({id: f.id, name: f.name, type: f.type, highlights: h.highlights});
            }
          })
        });
      }
    });

    this.isDataLoading = false;
  }
}
</script>
