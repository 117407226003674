<template>
  <fragment>
    <div v-if="!isEmailSending">
      <strong>Subject:</strong>
      {{ bidInvitation.bidInvitationEmail.subject }}
      <div class="email-preview">
        <p>{{ messageGreeting }}</p>
        <span v-html="messagePreview"></span>
        <p>
          <a href="#" class="view-project-link" @click.prevent>View Project</a>
        </p>
      </div>
      <div class="container compose-email-footer">
        <div class="row">
          <div class="col">
            <a href="#" @click.prevent="goBack">Go back</a>
          </div>
          <div class="col text-right">
            <button class="btn btn-success" @click="sendEmail">Send Email</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div>Sending bid invitations to the selected companies...</div>
      <div>
        <loading :active.sync="isEmailSending" :is-full-page="false"></loading>
      </div>
    </div>
  </fragment>
</template>

<style lang="scss" scoped>
.email-preview {
  border: 2px solid black;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 10rem;
}

.response-buttons {
  margin-top: 3rem;
}

.view-project-link {
  text-decoration: underline;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import ShowError from "@/utils/errorMessage";

export default {
  name: "preview",
  data: function() {
    return {
      preview: "",
      isEmailSending: false
    };
  },
  props: {
    bidInvitation: { type: Object }
  },
  computed: {
    messagePreview () {
      return this.bidInvitation.bidInvitationEmail.body
        .replace("{{ProjectName}}", this.getProject.title);
    },
    messageGreeting () {
      return `Dear Contact`;
    },
    ...mapGetters(["getProject"])
  },
  methods: {
    async sendEmail() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      this.isEmailSending = true;

      this.sendProjectITBFollowUp(this.bidInvitation)
        .then(() => this.$emit("goToNextStage"))
        .catch(() => {
          this.isEmailSending = false;
          ShowError.call(this);
        });
    },
    goBack() {
      this.$emit("goToPreviousStage");
    },
    ...mapActions(["sendProjectITBFollowUp"])
  }
};
</script>
