<template>
  <fragment>
    <div v-if="getProject.allowContactsView">
      <h3>Project Contacts</h3>

      <table class="table" v-if="getProject.owner">
        <thead>
          <tr>
            <th> Owner </th>
            <th> Information </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan="2" style="width: 40%"> {{ getProject.owner.name }} </td>
            <td v-if="getProject.owner.showInfo">
              {{ getAddressDisplay(getProject.owner.address) }}
              <span class="small-grey" v-if="getProject.owner.phone"><br /> ph. {{ getProject.owner.phone }}</span>
            </td>
          </tr>
          <tr v-if="getProject.owner.showInfo">
            <td class="preserved-content" v-html="getProject.owner.additionalInformation"></td>
          </tr>
        </tbody>
      </table>

      <div class="alert alert-warning mt-3" role="alert" v-else>
        Project Owner is not available
      </div>

      <table class="table" v-for="(grouping, key) in groupedContacts" :key="key">
        <thead>
          <tr>
            <th> {{ key }} </th>
            <th> Information </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(contact, index) in grouping">
            <tr :key="'contact-company-address-' + index">
              <td rowspan="2" style="width: 40%"> {{ contact.companyName || '---' }} </td>
              <td v-if="contact.showInfo">
                {{ getAddressDisplay(contact.address) }}
                <span class="small-grey" v-if="contact.phone"><br /> ph. {{contact.phone }}</span>
              </td>
            </tr>
            <tr v-if="contact.showInfo" :key="'contact-company-' + index + '-additionalinfo'">
              <td class="preserved-content" v-html="contact.additionalInformation"></td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <div v-else>
      <div class="alert alert-warning d-flex align-items-center d-print-none">
        <i class="fas fa-exclamation-triangle mr-3"></i>
        <span v-html="messageAccessDenied"></span>
      </div>
    </div>

  </fragment>
</template>

<style scoped>
.preserved-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}
</style>

<script>
import { mapGetters } from "vuex";
import AddressDisplayMixin from "@/mixins/addressDisplay";
import groupBy from 'lodash/groupBy';

export default {
  name: "project-info-contacts",
  mixins: [AddressDisplayMixin],
  data: function () {
    return {
      messageAccessDenied: process.env.VUE_APP_TEMPLATE_PROJECT_INFO_ACCESS_DENIED
    }
  },
  computed: {
    groupedContacts () {
      return groupBy(this.getProject.contacts, 'contactType')
    },
    ...mapGetters(["getProject"])
  }
};
</script>
