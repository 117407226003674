import store from '@/store/'

export function getUserCompanyName() {
    let userInfo = store.getters.getUser;

    if (userInfo.companies.length == 0) {
        return "No company name found";
    }

    return userInfo.companies[0].name;
}

