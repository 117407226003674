<template>
  <fragment>
    <form v-on:submit.prevent>
      <div class="modal-header">
        <h5 class="modal-title">Compose Invite to Bid Email</h5>

        <button type="button" class="close" aria-label="Close" @click="closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <Compose v-if="isComposeStage()" @goToNextStage="setStageToPreview" @updateEmail="emailUpdated" :email="bidInvitation.bidInvitationEmail" :template="bidInvitation.bidInvitationEmail.body"></Compose>
        <Preview v-if="isPreviewStage()" @goToNextStage="setStageToConfirmed" @goToPreviousStage="setStageToCompose" :selectedCompanies="selectedCompanies" :bidInvitation="bidInvitation"></Preview>
        <Confirmed v-if="isConfirmedStage()" :selectedCompanies="selectedCompanies"></Confirmed>
      </div>
    </form>
  </fragment>
</template>

<script>
  import Compose from "@/views/project/components/invite-2-bid/follow-up-email/compose.vue";
  import Preview from "@/views/project/components/invite-2-bid/follow-up-email/preview.vue";
  import Confirmed from "@/views/project/components/invite-2-bid/follow-up-email/confirmed.vue";
  import { Invite2BidEmailStages } from "@/utils/enums";
  import { mapGetters } from "vuex";

  export default {
    name: "email",
    components: {
      Compose,
      Preview,
      Confirmed
    },
    mixins: [],
    computed: {
      ...mapGetters(["getProject", "getUser"])
    },
    props: {
      selectedCompanies: { type: Array }
    },
    data: function() {
      return {
        stage: null,
        bidInvitation:
        {
            projectId: "",
            bidInvitationEmail: {
              to: "",
              cc: "",
              bcc: "",
              subject: "",
              body: ""
            }
        }
      };
    },
    mounted: function () {
      this.bidInvitation.BidInvitationInvitees = this.selectedCompanies;
      this.bidInvitation.bidInvitationEmail.body = '';
      this.bidInvitation.bidInvitationEmail.subject = '';
      this.bidInvitation.projectId = this.getProject.id;
      this.stage = Invite2BidEmailStages.COMPOSE;
    },
    methods: {
      closeModal() {
        this.$bvModal.hide("bv-modal-follow-up-email");
      },
      emailUpdated(email) {
        this.bidInvitation.bidInvitationEmail = email;
      },
      isComposeStage() {
        return this.stage === Invite2BidEmailStages.COMPOSE;
      },
      isPreviewStage() {
        return this.stage === Invite2BidEmailStages.PREVIEW;
      },
      isConfirmedStage() {
        return this.stage === Invite2BidEmailStages.CONFIRMED;
      },
      setStageToCompose() {
        this.stage = Invite2BidEmailStages.COMPOSE;
      },
      setStageToPreview() {
        this.stage = Invite2BidEmailStages.PREVIEW;
      },
      setStageToConfirmed() {
        this.stage = Invite2BidEmailStages.CONFIRMED;
      }
    }
  };
</script>
