var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col"},[_c('data-table',{attrs:{"data":_vm.searchResults.results,"columns":_vm.columns,"total":_vm.searchResults.total,"query":_vm.searchCriteria,"selectedRows":_vm.selectedRecords,"selectable":true,"isLoading":_vm.isDataLoading,"column-filter":true,"isLocalData":_vm.isLocalData},on:{"selected-columns-changed":_vm.onSelectedColumnsChanged,"column-sort-changed":_vm.onColumnSortChanged,"page-size-changed":_vm.onPageSizeChanged},scopedSlots:_vm._u([{key:"companyName",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.companyName)+" ")]}},{key:"contactName",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.contactName)+" ")]}},{key:"title",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.title || 'N/A')+" ")]}},{key:"email",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.email)+" ")]}},{key:"city",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.city || 'N/A')+" ")]}},{key:"province",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.province || 'N/A')+" ")]}},{key:"loading",fn:function(){return [_c('loading',{attrs:{"active":_vm.isDataLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isDataLoading=$event}}})]},proxy:true},{key:"no-records",fn:function(){return [_c('div',{staticClass:"alert alert-warning",attrs:{"role":"alert"}},[_vm._v("No records found.")])]},proxy:true}])})],1)]),(_vm.searchResults.total > 0)?_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col"},[_c('button',{staticClass:"btn btn-success",on:{"click":_vm.addToList}},[_vm._v("Add to List")])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }