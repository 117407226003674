import { render, staticRenderFns } from "./keyword-highlight-file-item.vue?vue&type=template&id=a06e5fea&scoped=true&"
import script from "./keyword-highlight-file-item.vue?vue&type=script&lang=js&"
export * from "./keyword-highlight-file-item.vue?vue&type=script&lang=js&"
import style0 from "./keyword-highlight-file-item.vue?vue&type=style&index=0&id=a06e5fea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a06e5fea",
  null
  
)

export default component.exports