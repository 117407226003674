<template>
  <fragment>
    <div class="heading">
      <div class="row">
        <div class="col text-left">
          <h3>My Invitations</h3>
        </div>
        <div class="col text-right d-print-none">
          <button type="button" class="btn btn-link" @click="exportInvitations">
            <i class="fas fa-file-export"></i>
            Export
          </button>
        </div>
      </div>
    </div>

    <div class="container invitation-stats">
      <div class="row align-middle">
        <div class="col">
          <strong>Total Invited:</strong> {{ bidInvites.length }}
        </div>

        <div class="col" :key="statusType.status" v-for="statusType in bidInvitationStatusTypes">
          <strong>{{statusType.status}}:</strong> {{ getStatusTotal(statusType.status) }}
        </div>
      </div>
    </div>
    <div class="alert alert-warning mt-3" role="alert" v-if="bidInvites.length == 0">
      No bid invitations available. <a href="#" @click.prevent="openITBModel">Send an invite</a>.
    </div>

    <div class="card mb-3" v-else>
      <div class="card-body">
        <data-table
            ref="invitees"
            :data="bidInvites"
            :columns="columns"
            :total="bidInvites.length"
            :query="searchCriteria"
            :column-filter="true"
            :is-local-data="true"
            @selected-columns-changed="onSelectedColumnsChanged"
            @column-sort-changed="onColumnSortChanged"
            @page-size-changed="onPageSizeChanged">
          <template v-slot:header-custom-btns>
            <button type="button" class="btn btn-primary" @click="emailContacts">Email Contacts</button>
          </template>
          <template v-slot:companyName="{ row }">
            {{ row.companyName }}
          </template>
          <template v-slot:contactName="{ row }">
            {{ row.contactName }}
          </template>
          <template v-slot:email="{ row }">
            {{ row.email }}
          </template>
          <template v-slot:tradeType="{ row }">
            <span v-if="row.tradeType"> {{ row.tradeType }} </span>
            <span v-else> N/A </span>
          </template>
          <template v-slot:lastContactDate="{ row }">
            <span v-if="row.lastContactDate"> {{ row.lastContactDate | datetime('MMMM dd, yyyy h:mmaa') }} {{ getProject.timeZone.code }} </span>
            <span v-else> N/A </span>
          </template>
          <template v-slot:status="{ row }">
            <div class="row align-bottom">
              <div class="col" :class="getStatusClass(row.status)">
                <strong>{{ row.status }}</strong>
              </div>
              <div class="col d-print-none">
                <a v-if="statusIsNotBidding(row.status)" href="javascript:void(0)" @click="openRemindModal(row)">Remind</a>
              </div>
            </div>
          </template>
          <template v-slot:responseDate="{ row }">
            <span v-if="row.responseDate"> {{ row.responseDate | datetime('MMMM dd, yyyy h:mmaa') }} {{ getProject.timeZone.code }} </span>
            <span v-else> N/A </span>
          </template>
          <template v-slot:no-records>
            <div class="alert alert-warning" role="alert">No records found.</div>
          </template>
        </data-table>
      </div>
    </div>

    <b-modal id="bv-modal-remind-bidder" size="xl" hide-header hide-footer :no-close-on-backdrop="true">
      <Remind :invitation="selectedBidInvitation" :getSearchResults="getSearchResults"></Remind>
    </b-modal>

    <b-modal id="bv-modal-follow-up-email" size="lg" hide-header hide-footer :no-close-on-backdrop="true">
      <follow-up-email :selectedCompanies="filteredRecords"></follow-up-email>
    </b-modal>
  </fragment>
</template>

<style lang="scss" scoped>

  .invitation-stats {
    margin-left: inherit;
    margin-right: inherit;
    margin-bottom: 3rem;
  }

  .oval {
    width: 150px;
    border-radius: 40px;
    text-align: center;
    padding: .75rem;
  }

  .bidding {
    @extend .oval;
    background: #6fff6f;
  }

  .not-bidding {
    @extend .oval;
    background: #ffc9d3;
  }

  .no-response {
    @extend .oval;
    background: #fdd39d;
  }

  .heading {
    margin-right: 2rem;
  }
</style>

<script>
  import { BidInvitationService } from "@/services/";
  import { mapGetters, mapActions } from "vuex";
  import { DataTable } from "@/components/datatable/";
  import Remind from "@/views/project/components/invite-2-bid/remind.vue";
  import FollowUpEmail from "@/views/project/components/invite-2-bid/follow-up-email/email.vue";
  import ShowError from "@/utils/errorMessage";
  import FileDownload from "@/utils/fileDownload";

  const STORAGE_KEY_PROJECT_BIDINVITATIONS_TABLE_SELECTED_COLUMNS = 'project-bidinvitation-table-selected-columns';
  const STORAGE_KEY_PROJECT_BIDINVITATIONS_TABLE_COLUMN_SORTING = 'project-bidinvitation-table-column-sorting';
  const STORAGE_KEY_PROJECT_BIDINVITATIONS_TABLE_PAGE_SIZE = 'project-bidinvitation-table-page-size';

  const defaultSearchCriteria = {
    page: 1,
    pageSize: 10,
    sortOrder: null,
    sortDirection: null
  };

  export default {
    name: "project-info-invitations",
    components: {
      'data-table': DataTable,
      Remind,
      'follow-up-email': FollowUpEmail
    },
    beforeMount() {
      let savedColumnSort = JSON.parse(window.localStorage.getItem(STORAGE_KEY_PROJECT_BIDINVITATIONS_TABLE_COLUMN_SORTING)) || {};
      let savedPageSort = JSON.parse(window.localStorage.getItem(STORAGE_KEY_PROJECT_BIDINVITATIONS_TABLE_PAGE_SIZE)) || {};
      this.searchCriteria = { ...defaultSearchCriteria, ...savedColumnSort, ...savedPageSort, ...this.$route.query };
    },
    mounted: function () {
      this.setSelectionTypes();
    },
    data: function () {
      return {
        bidInvitationStatusTypes: [],
        searchCriteria: {},
        filteredRecords: [],
        searchResults: { total: 0, results: [] },
        completeResults: [],
        columns: [
          {
            display: "Company Name",
            field: "companyName",
            exportFieldName: "companyName",
            sortable: true
          },
          {
            display: "Contact Name",
            field: "contactName",
            exportFieldName: "contactName",
            sortable: true
          },
          {
            display: "Email",
            field: "email",
            exportFieldName: "email",
            sortable: true
          },
          {
            display: "Trade Type",
            field: "tradeType",
            exportFieldName: "tradeType",
            sortable: true
          },
          {
            display: "Last Contact Date",
            field: "lastContactDate",
            exportFieldName: "lastContactDate",
            sortable: true
          },
          {
            display: "Status",
            field: "status",
            exportFieldName: "status",
            sortable: true,
            filterable: true
          },
          {
            display: "Response Received",
            field: "responseDate",
            exportFieldName: "responseDate",
            sortable: true
          },

          { ignore: true }
        ],
        selectedBidInvitation: {}
      };
    },
    computed: {
      bidInvites () {
        return this.getProject.bidInvitations;
      },
      ...mapGetters(["getProject"])
    },
    methods: {
      ...mapActions([
        "fetchBidInvitationStatusTypes",
      ]),
      emailContacts () {
        this.filteredRecords = this.$refs.invitees.localDataset;
        this.$bvModal.show("bv-modal-follow-up-email");
      },
      onPageSizeChanged(pageSize) {
        window.localStorage.setItem(STORAGE_KEY_PROJECT_BIDINVITATIONS_TABLE_PAGE_SIZE, JSON.stringify(pageSize));
      },
      onSelectedColumnsChanged(columns) {
        let activeColumns = columns.map(column => ({ field: column.field, isVisible: column.isVisible }));

        window.localStorage.setItem(STORAGE_KEY_PROJECT_BIDINVITATIONS_TABLE_SELECTED_COLUMNS, JSON.stringify(activeColumns));
      },
      onColumnSortChanged(sort) {
        window.localStorage.setItem(STORAGE_KEY_PROJECT_BIDINVITATIONS_TABLE_COLUMN_SORTING, JSON.stringify(sort));
      },
      openITBModel () {
        this.$emit('openITB');
      },
      getSearchResults() {
        // TODO: remove component requirement to need this fn
      },
      setSelectionTypes() {
        Promise.all([
          this.fetchBidInvitationStatusTypes()
        ])
          .then(result => {
            this.bidInvitationStatusTypes = result[0];
          })
          .catch(ShowError.bind(this));
      },
      getStatusTotal(status) {
        return this.getProject.bidInvitations.filter(bic => bic.status === status).length
      },
      getStatusClass(status) {
        if (status === "Bidding") {
          return "bidding";
        }
        else if (status === "Not Bidding") {
          return "not-bidding"
        }
        else {
          return "no-response";
        }
      },
      statusIsNotBidding(status) {
        return status === "No Response"
      },
      openRemindModal(invitation) {
        this.selectedBidInvitation = invitation;
        this.$bvModal.show("bv-modal-remind-bidder");
      },
      exportInvitations() {
        return BidInvitationService.excel({ search: { ...this.searchCriteria, projectId: this.getProject.id } }).then(response => {
          FileDownload("BidInvitationExport.xlsx", response.data);
        });
      }
    }
  };
</script>
