<template>
  <fragment>
    <div class="container">
      <div class="form-row">
        <div class="col">
          <data-table :data="searchResults.results"
                      :columns="columns"
                      :total="searchResults.total"
                      :query="searchCriteria"
                      :selectedRows="selectedRecords"
                      :selectable="true"
                      :isLoading="isDataLoading"
                      :column-filter="true"
                      :isLocalData="isLocalData"
                      @selected-columns-changed="onSelectedColumnsChanged"
                      @column-sort-changed="onColumnSortChanged"
                      @page-size-changed="onPageSizeChanged">
            <template v-slot:companyName="{ row }">
              {{ row.companyName }}
            </template>
            <template v-slot:contactName="{ row }">
              {{ row.contactName }}
            </template>
            <template v-slot:title="{ row }">
              {{ row.title || 'N/A' }}
            </template>
            <template v-slot:email="{ row }">
              {{ row.email }}
            </template>
            <template v-slot:city="{ row }">
              {{ row.city || 'N/A' }}
            </template>
            <template v-slot:province="{ row }">
              {{ row.province || 'N/A' }}
            </template>
            <template v-slot:loading>
              <loading :active.sync="isDataLoading" :is-full-page="false"></loading>
            </template>
            <template v-slot:no-records>
              <div class="alert alert-warning" role="alert">No records found.</div>
            </template>
          </data-table>
        </div>
      </div>
      <div class="form-row" v-if="searchResults.total > 0">
        <div class="col">
          <button class="btn btn-success" @click="addToList">Add to List</button>
        </div>
      </div>
    </div>
  </fragment>
</template>

<style lang="scss" scoped>
  .existing-list {
    padding-top: 2rem;
  }

  .already-received-invitation
  {
    margin-left:1rem;
  }
</style>

<script>
  import { DataTable } from "@/components/datatable/";
  import { mapGetters } from "vuex";
  import debounce from "lodash/debounce";

  import {
    STORAGE_KEY_INVITE2BID_COMPANY_TABLE_SELECTED_COLUMNS,
    STORAGE_KEY_INVITE2BID_COMPANY_TABLE_COLUMN_SORTING,
    STORAGE_KEY_INVITE2BID_COMPANY_TABLE_PAGE_SIZE,
    columns
  } from "@/views/project/components/invite-2-bid/datatable-elements";

  const defaultSearchCriteria = {
    page: 1,
    pageSize: 10,
    sortOrder: null,
    sortDirection: null
  };

  export default {
    name: "company-listing",
    components: {
      'data-table': DataTable,
    },
    props: {
      isLocalData: { type: Boolean, default: true },
      setCompanyOptions: { type: Function },
      searchResults: { type: Object },
      isDataLoading: { type: Boolean },
      getSearchResults: { type: Function }
    },
    computed: {
      sortWatcherProperties() {
        return `${this.searchCriteria.sortDirection}|${this.searchCriteria.sortOrder}`;
      },
      ...mapGetters(["getProject"])
    },
    data: function () {
      return {
        showSidebar: true,
        searchCriteria: {},
        selectedRecords: [],
        columns: columns,
      };
    },
    watch: {
      "searchCriteria.page": function (newValue, oldValue) {
        if (!this.isLocalData && typeof oldValue !== 'undefined' && newValue !== oldValue) {
          this.updateSearchResults();
        }
      },
      "searchCriteria.pageSize": function (newValue, oldValue) {
        if (!this.isLocalData && typeof oldValue !== 'undefined' && newValue !== oldValue) {
          this.validatePage();
          this.updateSearchResults();
        }
      },
      sortWatcherProperties: debounce(function(newValue, oldValue) {
        const [newPropertyA, newPropertyB] = newValue.split('|');
        const [oldPropertyA, oldPropertyB] = oldValue.split('|');

        if (!this.isLocalData &&
          (oldPropertyA !== 'undefined' && oldPropertyA !== null && oldPropertyA !== newPropertyA) ||
          (oldPropertyB !== 'undefined' && oldPropertyB !== null && oldPropertyB !== newPropertyB)
        ) {
          this.updateSearchResults();
        }
      }, 100, false)
    },
    beforeMount() {
      let savedColumnSort = JSON.parse(window.localStorage.getItem(STORAGE_KEY_INVITE2BID_COMPANY_TABLE_COLUMN_SORTING)) || {};
      let savedPageSort = JSON.parse(window.localStorage.getItem(STORAGE_KEY_INVITE2BID_COMPANY_TABLE_PAGE_SIZE)) || {};
      this.searchCriteria = { ...defaultSearchCriteria, ...savedColumnSort, ...savedPageSort, ...this.$route.query };
    },
    methods: {
      onPageSizeChanged(pageSize) {
        window.localStorage.setItem(STORAGE_KEY_INVITE2BID_COMPANY_TABLE_PAGE_SIZE, JSON.stringify(pageSize));
      },
      onSelectedColumnsChanged(columns) {
        let activeColumns = columns.map(column => ({ field: column.field, isVisible: column.isVisible }));
        window.localStorage.setItem(STORAGE_KEY_INVITE2BID_COMPANY_TABLE_SELECTED_COLUMNS, JSON.stringify(activeColumns));
      },
      onColumnSortChanged(sort) {
        window.localStorage.setItem(STORAGE_KEY_INVITE2BID_COMPANY_TABLE_COLUMN_SORTING, JSON.stringify(sort));
      },
      addToList() {
        let list = this.searchResults.results
          .filter(result => this.selectedRecords.filter(selectedRecord => selectedRecord == result.id).length > 0);

        this.setCompanyOptions(list);
      },
      updateSearchResults() {
        this.getSearchResults(this.getSavedColumnSort(), { ...this.getSavedPageSort(), page: this.searchCriteria.page });
      },
      getSavedColumnSort() {
        return JSON.parse(window.localStorage.getItem(STORAGE_KEY_INVITE2BID_COMPANY_TABLE_COLUMN_SORTING));
      },
      getSavedPageSort() {
        return JSON.parse(window.localStorage.getItem(STORAGE_KEY_INVITE2BID_COMPANY_TABLE_PAGE_SIZE));
      },
      //Resetting to maximum page when the page number no longer applies
      validatePage() {
        let maxPageIndex = Math.ceil(this.searchResults.total / this.searchCriteria.pageSize);

        if (this.searchCriteria.page > 1 && (this.searchCriteria.page > maxPageIndex)) {
          this.searchCriteria.page = maxPageIndex;
        }
      },
      companyAlreadyContacted(company) {
        return this.getProject.bidInvitations.find(bi => bi.bidInvitationCompanies.find(bic => bic.companyId === company.id));
      }
    }
  };
</script>
