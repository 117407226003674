<template>
  <fragment>
    <div v-if="getProject.allowBiddersView">
      <h3>Project Bidders</h3>
      <div class="alert alert-warning mt-3" role="alert" v-if="!groupedBids">
        No bidders available
      </div>
      <div
        class="card mb-3"
        v-for="(bids, key) in groupedBids"
        :key="key"
      >
        <div class="card-body">
          <h5 class="card-title">{{ key }}</h5>
          <table class="table">
            <thead>
              <tr>
                <th scope="col" style="width: 35%">Company</th>
                <th scope="col" style="width: 35%">Contact</th>
                <th scope="col">Submitted</th>
                <th scope="col">Awarded</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(bid, index) in bids" :key="'bid-' + key + '-' + index">
                <td><a :href="publicDirectoryLink + bid.company.alias" target="_blank">{{ bid.company.name }}</a></td>
                <td>
                  <span v-if="bid.displayUser && bid.user">{{ bid.user | fullname }} <br /> </span>
                  {{ getAddressDisplay(bid.company.address) }}
                  <span v-if="bid.displayUser && bid.user && bid.user.email"> <br /> {{ bid.user.email }} </span>
                  <span v-if="!bid.displayUser && bid.company && bid.company.phone"> <br /> {{ bid.company.phone }} </span>
                  <span v-if="!bid.displayUser && bid.company && bid.company.email"> <br /> {{ bid.company.email }} </span>
                </td>
                <td>{{ bid.submittedAmount | currency }}</td>
                <td>{{ bid.awardedAmount | currency }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <template v-if="this.getProject.externalPlanHolders">
        <h3>External Plan Holders</h3>
        <div class="row">
          <div class="col-md-12" v-html="displayExternalPlanHolders"></div>
        </div>
      </template>
    </div>

    <div v-else>
      <div class="alert alert-warning d-flex align-items-center d-print-none">
        <i class="fas fa-exclamation-triangle mr-3"></i>
        <span v-html="messageAccessDenied"></span>
      </div>
    </div>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddressDisplayMixin from "@/mixins/addressDisplay";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";

export default {
  name: "project-info-bidders",
  mixins: [AddressDisplayMixin],
  data: function () {
    return {
      messageAccessDenied: process.env.VUE_APP_TEMPLATE_PROJECT_INFO_ACCESS_DENIED,
      publicDirectoryLink: null
    }
  },
  computed: {
    displayExternalPlanHolders () {
      return this.getProject.externalPlanHolders.replace(/\n/gi, '<br />');
    },
    groupedBids() {
      if (!this.getProject.bids || this.getProject.bids.length === 0) return null;
      let bids = this.getProject.bids;
      let sortedBids = sortBy(bids, b => b.company.name.toLowerCase());
      return groupBy(sortedBids, "participantType");
    },
    ...mapGetters(["getProject", "getPublicDirectoryLink"])
  },
  methods: {
    ...mapActions(["fetchClientSettings"]),
  },
  created() {
    this.fetchClientSettings().then(() => {     
      this.publicDirectoryLink = this.getPublicDirectoryLink;      
    });
  }
};
</script>
