<template>
  <fragment>
    <form v-on:submit.prevent="onSubmit">
      <div class="modal-header">
        <h5 class="modal-title">Invite to Bid - Select Companies</h5>
        <button type="button" class="close" aria-label="Close" @click="closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <loading :active.sync="isPageLoading" is-full-page></loading>
      <div class="modal-body">
        <div class="container">
          <div class="form-row">
            <div class="col-8">
              <p>How would you like to select companies? Choose an option below to get started.</p>

              <div class="selection-types">
                <div v-for="selectionType in bidInvitationSelectionTypes" :key="selectionType.id" class="custom-control custom-radio">
                  <input :id="'selection-type-' + selectionType.id" name="selectionType" type="radio" class="custom-control-input"
                         :value="selectionType.id" v-model="currentSelectionType">
                  <label class="custom-control-label" :for="'selection-type-' + selectionType.id">{{selectionType.description}}</label>
                </div>
              </div>

              <ExistingList v-if="existingListSelected()" :setCompanyOptions="setCompanyOptions" :lists="lists"></ExistingList>
              <Search v-if="searchSelected()" :setCompanyOptions="setCompanyOptions"></Search>
              <PreviousProjects v-if="previousProjectsSelected()" :setCompanyOptions="setCompanyOptions"></PreviousProjects>
              <AddressBookList v-if="addressbookSelected()" :setCompanyOptions="setCompanyOptions"></AddressBookList>
            </div>
            <div class="col">
              <h4>Invitation List</h4>

              <div v-if="selectedCompanies.length == 0">
                You have not yet selected any companies to invite for a bid. Please make your selections on the left.
              </div>
              <div v-else>
                <div class="container">
                  <div class="form-row selected-companies-header">
                    <div class="col text-left">
                      <strong>Invite</strong>
                    </div>
                    <div class="col text-center underline">
                      <a href="javascript:void(0)" @click="removeAllCompanies()">Remove All</a>

                    </div>
                  </div>
                  <div class="form-row" :key="selectedCompany.id" v-for="selectedCompany in selectedCompanies">
                    <div class="col text-left">
                      {{ selectedCompany.contactName }} - {{ selectedCompany.companyName}}
                    </div>
                    <div class="col text-center">
                      <a href="javascript:void(0)" @click="unselectCompany(selectedCompany.id)"><span class="fas fa-trash"></span></a>
                    </div>
                  </div>
                  <div class="form-row save-list-link underline">
                    <div class="col-12 text-center">
                      <a href="javascript:void(0)" @click="openSaveListModal">Save this list?</a>
                    </div>

                  </div>
                  <div class="form-row compose-email-button">
                    <div class="col-12 text-center">
                      <button class="btn btn-success" @click="openComposeEmailModal()">Compose Email</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-modal id="bv-modal-invite-2-bid-email" size="lg" hide-header hide-footer :no-close-on-backdrop="true">
        <Email :changeToInvitationsTab="changeToInvitationsTab" :selectedCompanies="selectedCompanies" :selectionTypeId="currentSelectionType"></Email>
      </b-modal>

      <b-modal id="bv-modal-invite-2-bid-save-list" size="lg" hide-header hide-footer :no-close-on-backdrop="true">
        <SaveList :selectedCompanies="selectedCompanies"></SaveList>
      </b-modal>
    </form>
  </fragment>
</template>

<style lang="scss" scoped>
  .selected-companies-header {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .compose-email-button {
    padding-top: 75px;
  }

  .save-list-link {
    margin-top: 1rem;
  }

  .underline
  {
    text-decoration:underline;
  }
</style>

<script>
  import { mapActions } from "vuex";
  import { alpha as alphaSort } from "@/utils/sort";
  import Toast from "@/utils/toast";
  import ExistingList from "@/views/project/components/invite-2-bid/existing-list.vue";
  import PreviousProjects from "@/views/project/components/invite-2-bid/previous-projects.vue";
  import AddressBookList from "@/views/project/components/invite-2-bid/address-book-list.vue";
  import Search from "@/views/project/components/invite-2-bid/search.vue";
  import Email from "@/views/project/components/invite-2-bid/email/email.vue";
  import SaveList from "@/views/project/components/invite-2-bid/save-list.vue";
  import ShowError from "@/utils/errorMessage";

  export default {
    name: "invite-2-bid",
    components: {
      ExistingList,
      Search,
      Email,
      SaveList,
      PreviousProjects,
      AddressBookList
    },
    props: {
      changeToInvitationsTab: { Type: Function }
    },
    mixins: [],
    data: function () {
      return {
        isPageLoading: true,
        currentSelectionType: {},
        bidInvitationSelectionTypes: [],
        selectedCompanies: [],
        companyOptions: [],
        lists: []
      };
    },
    mounted: function () {
      this.setSelectionTypes();
    },
    methods: {
      ...mapActions([
        "fetchBidInvitationSelectionTypes",
      ]),
      async onSubmit() {
        return;
      },
      closeModal() {
        this.$bvModal.hide("bv-modal-invite-2-bid");
      },
      setSelectionTypes() {
        Promise.all([
          this.fetchBidInvitationSelectionTypes()
        ])
          .then(result => {
            this.bidInvitationSelectionTypes = result[0];
            this.isPageLoading = false;
          })
          .catch(ShowError.bind(this));
      },
      existingListSelected() {
        return this.getCurrentSelectionType().name == "ExistingList";
      },
      searchSelected() {
        return this.getCurrentSelectionType().name == "Search";
      },
      previousProjectsSelected() {
        return this.getCurrentSelectionType().name == "PreviousProjectBidders";
      },
      addressbookSelected () {
        return this.getCurrentSelectionType().name === "AddressBook";
      },
      getCurrentSelectionType() {
        let selectedTypes = this.bidInvitationSelectionTypes.filter(st => st.id == this.currentSelectionType);

        if (selectedTypes.length > 0) {
          return selectedTypes[0];
        }
        else {
          return {
            name: "None",
            description: "",
            id: "",
            createddate: new Date("")
          };
        }
      },
      setCompanyOptions(companyOptions) {
        if (companyOptions.length > 0) {
          this.selectedCompanies = this.selectedCompanies.concat(companyOptions.filter(company => this.selectedCompanies.filter(selectedCompany => selectedCompany.id === company.id).length === 0)).sort((company1, company2) => alphaSort(company1.name, company2.name));

          Toast.success(this, "Added selected companies to the invitation list!");
        }
      },
      unselectCompany(companyId) {
        this.selectedCompanies = this.selectedCompanies.filter(c => c.id != companyId).sort(this.sortCompanies);
      },

      openComposeEmailModal() {
        this.$bvModal.show("bv-modal-invite-2-bid-email");
      },
      openSaveListModal() {
        this.$bvModal.show("bv-modal-invite-2-bid-save-list");
      },
      removeAllCompanies() {
        this.selectedCompanies = [];
      }
    }
  };
</script>
