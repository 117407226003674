<template>
  <div :class="['list-group-item', { 'pb-0': nodes.length }]" href="#">
    <div class="row" @click="toggleCollapse">
      <div class="col-xs-12 col-sm-7">
        <div :style="indent">
          <input type="checkbox" class="checkbox-file mr-2" :value="target.id" :checked="!!selectedFileIds.find(i => i === target.id)"
            @change="onSelectRecord($event.target.value, target.size)" @click="toggleCollapse" />
          <span v-if="isFile" class="download-link" @click="downloadFile(target.id, target.name, target.type, target.size)">
            <i :class="['far', icon, 'fa-lg mr-1']"></i> {{ target.name }}
          </span>
          <span v-else>
            <i :class="['far', icon, 'fa-lg mr-1']"></i> {{ target.name }}
          </span>
        </div>
      </div>
      <div class="col-xs-8 col-sm-2">{{ target.createdOn | datetime('eee, MMM dd yyyy') }}</div>
      <div class="col-xs-4 col-sm-2">{{ target.type }}</div>
      <div class="col-xs-10 col-sm-1">
        <span v-if="isFile" class="download-link" @click="downloadFile(target.id, target.name, target.type, target.size)">
          <i class="fas fa-download"></i>
        </span>
        <span v-else> &nbsp; </span>
      </div>
    </div>

    <div class="list-group" v-if="!isCollapsed">
      <info-documents-item v-for="(node, index) in nodes"
                           :key="'folder-item-' + depth + '-' + index"
                           :nodes="node.children"
                           :target="node"
                           :downloadFn="downloadFn"
                           :selectedFileIds.sync="selectedFileIds"
                           :selectedFilesSize.sync="selectedFilesSize"
                           :depth="depth + 1">
      </info-documents-item>
    </div>
  </div>
</template>

<style scoped>

  .row {
    padding: .75rem;
  }

  .list-group,
  .list-group-item {
    padding: 0;
  }

    .list-group.list-group-root .list-group-item {
      border-radius: 0;
      border-width: 1px 0 0 0;
    }

  .download-link {
    cursor: pointer;
  }

  i.fa-folder, i.fa-folder-open {
    cursor: pointer;
  }
</style>

<script>
  import { ApplicationActivityService } from "@/services/";
  
  export default {
    name: 'info-documents-item',
    props: {
      target: { type: Object },
      nodes: { type: Array },
      depth: { type: Number },
      downloadFn: { type: Function },
      selectedFileIds: { type: Array },
      selectedFilesSize: { type: Array }
    },
    data() {
      return { isCollapsed: true };
    },
    computed: {
      icon() {
        if (this.isFolder) {
          return this.isCollapsed ? 'fa-folder' : 'fa-folder-open';
        }
        return 'fa-file';
      },
      isFile() {
        return !this.isFolder;
      },
      isFolder() {
        return this.target.type.toUpperCase() === 'FOLDER';
      },
      indent() {
        return { transform: `translate(${this.depth * 15}px` };
      }
    },
    methods: {
      getDownloadFileActivity() {
        return {
          Product: "Link2Build",
          Action: "DownloadFile",
          ActionTargetId: this.target.id,
          ProductSubArea: "Project",
          ProductSubAreaId: this.$route.params.id
        };
      },
      onSelectRecord(id, fileSize) {     
        if (this.target.children) {
          this.addFileToDownload(id, fileSize);
          this.folderSelect(this.target);
        } else {
          this.addFileToDownload(id, fileSize)
        }

        this.$emit('clicked', this.selectedFileIds, this.selectedFilesSize);
      },
      folderSelect(targetChild) {
        for(let child of targetChild.children) {
          this.folderSelect(child);
        }

        if ((this.selectedFileIds.indexOf(this.target.id) >= 0 && this.selectedFileIds.indexOf(targetChild.id) >= 0)
         || (this.selectedFileIds.indexOf(this.target.id) === -1 && this.selectedFileIds.indexOf(targetChild.id) === -1)) return;
        
        this.addFileToDownload(targetChild.id, targetChild.size);
      },
      addFileToDownload(id, fileSize) {
        let pos = this.selectedFileIds.indexOf(id);
        let sizePos = this.selectedFilesSize.indexOf(fileSize);

        if (pos === -1) this.selectedFileIds.push(id);
        if (pos >= 0) this.selectedFileIds.splice(pos, 1);

        if (!isNaN(parseInt(fileSize))) {
          if (sizePos === -1) this.selectedFilesSize.push(fileSize);
          if (sizePos >= 0) this.selectedFilesSize.splice(sizePos, 1);
        }
      },
      downloadFile(fileId, filename, fileType, fileSize) {
        if (this.downloadFn) {
          ApplicationActivityService.create(this.getDownloadFileActivity())
          this.downloadFn(fileId, filename, fileType, fileSize);
        }
      },
      toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
      }
    }
  }
</script>
