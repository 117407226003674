<template>
  <div>
    <div class="modal-header">
      <h4 class="modal-title">Remind Bidder</h4>
    </div>

    <div class="modal-body">
      <div class="form-group">
        Are you sure you want to remind <strong>{{ getReminderName }}</strong> to bid on this project?
      </div>
    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-success" @click="remind">Remind</button>
      <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import Toast from "@/utils/toast";
  import { BidInvitationService } from "@/services/";

  export default {
    name: 'remind',
    props: {
      invitation: { type: Object },
      getSearchResults: { type: Function },
    },
    data: function () {
      return {
      }
    },
    computed: {
      getReminderName () {
        if (this.invitation.companyName === this.invitation.contactName)
          return this.invitation.companyName;

        return `${ this.invitation.contactName } at ${ this.invitation.companyName}`;
      }
    },
    methods: {
      closeModal() {
        this.$bvModal.hide("bv-modal-remind-bidder");
      },
      remind() {
        BidInvitationService.remind(this.invitation.id)
          .then(() => {
            Toast.success(this, "Successfully reminded company!");

            this.fetchProjectDetails(this.$route.params.id).then(() => {
              this.getSearchResults();
              this.$bvModal.hide("bv-modal-remind-bidder");
              this.closeModal();
            });
          })
          .catch(() => {
            Toast.danger(this, "Oops! There was an error.");
            this.closeModal();
          });
      },
      ...mapActions(["fetchProjectDetails"])
    }
  }
</script>
