<template>
    <div :class="['list-group-item', { 'pb-0': 1}]" href="#">
        <div>
            <div class="row">
                <div class="col-sm-1 text-center">
                    <span class="download-link" @click="toggleCollapse">
                        <i class="far fa-plus-square" v-if="isCollapsed"></i>
                        <i class="far fa-minus-square" v-if="!isCollapsed"></i>
                    </span>
                </div>                
                <div class="col-sm-7">
                    <span class="download-link" @click="downloadFile(file.id, file.name, file.type)">
                        <i class="far fa-lg mr-1"></i> {{ file.name }}
                    </span>
                </div>
                <div class="col-sm-3">
                    {{file.type}}
                </div>       
                <div class="col-sm-1 text-center">
                    <span class="download-link" @click="downloadFile(file.id, file.name, file.type)">
                        <i class="fas fa-download"></i>
                    </span>
                </div>                     
            </div>

            <div class="list-group" v-if="!isCollapsed">
                <div class="row" v-for="(highlight, index) in file.highlights" :key="index">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-11 text-left" v-html="highlight"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
.row {
  padding: .75rem;
}
.list-group,
.list-group-item {
  padding: 0;
}
.list-group.list-group-root .list-group-item {
  border-radius: 0;
  border-width: 1px 0 0 0;
}
.download-link {
  cursor: pointer;
}
i.fa-folder, i.fa-folder-open {
	cursor: pointer;
}
</style>

<script>
import { FileService } from "@/services/";
import FileDownload from "@/utils/fileDownload";
export default {
  name: "keyword-highlight-file-item",
  props: ["file"],
  components: {
  },
  data: function() {
    return { isCollapsed: true };
  },
  methods: {
    downloadFile(fileId, filename, fileType) {
      this.isDataLoading = true;
      return FileService.downloadFile(fileId).then(response => {
        FileDownload(filename + fileType, response.data);
        this.isDataLoading = false;
      });
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    }    
  }
}
</script> 