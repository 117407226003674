<template>
  <div class="mt-4">
    <div v-if="!isPageLoading">
      <div class="form-group" v-if="addressBooks.length > 0">
        <label for="addressbooklist">Please select an existing address book:</label>
        <select id="addressbooklist" name="addressbooklist" class="form-control" @change="addressBookSelected" v-model="selectedAddressBook">
          <option v-for="addressBook in addressBooks" :key="addressBook.id" :value="addressBook.id">{{ addressBook.name }}</option>
        </select>
      </div>
      <div v-else>
        Sorry, you have not yet set up any address books. Please choose another selection option. You may save your subsequent selections to a list for future selection.
      </div>
    </div>
    <loading v-else :active.sync="isDataLoading" :is-full-page="false"></loading>

    <div v-if="selectedAddressBook">
      <CompanyListing :setCompanyOptions="setCompanyOptions" :searchResults="searchResults" :getSearchResults="getSearchResults" :isDataLoading="isDataLoading" />
    </div>
  </div>
</template>

<script>
import { AddressBookService } from "@/services/";
import Toast from "@/utils/toast";
import CompanyListing from "@/views/project/components/invite-2-bid/company-listing.vue";

export default {
  name: "address-book-list",
  components: {
    CompanyListing
  },
  props: {
    setCompanyOptions: { type: Function }
  },
  data: function () {
    return {
      selectedAddressBook: null,
      addressBooks: [],
      isPageLoading: true,
      isDataLoading: false,
      searchResults: { total: 0, results: [] }
    }
  },
  methods: {
    initialize () {
      this.isDataLoading = true;

      AddressBookService.getForInviteToBid()
        .then(({ data }) => {
          this.addressBooks = data;
        })
        .catch(() => {
          let message = this.$createElement('span', {}, ['Oops! An error has occurred', this.$createElement('br'), 'Please contact an administrator']);
          Toast.danger(this, [message], { autoHideDelay: 7500 });
        })
        .finally(() => {
          this.isPageLoading = false;
          this.isDataLoading = false;
        });
    },
    addressBookSelected () {
      this.getSearchResults();
    },
    getSearchResults(sortColumn) {
      let companies = this.addressBooks.find(l => l.id == this.selectedAddressBook).contacts;

      if (sortColumn) {
        companies = companies.sort((cat1, cat2) => {
          let value1 = (sortColumn.sortOrder ? (cat1[sortColumn.sortOrder] || '') : cat1['companyName']).toLowerCase();
          let value2 = (sortColumn.sortOrder ? (cat2[sortColumn.sortOrder] || '') : cat2['companyName']).toLowerCase();

          if (value1 < value2) {
            return sortColumn.sortDirection === "asc" ? -1 : 1;
          }
          else if (value1 > value2) {
            return sortColumn.sortDirection === "asc" ? 1 : -1;
          }
          else {
            return 0;
          }
        });
      }

      this.searchResults.total = companies.length;
      this.searchResults.results = companies;
    },
  },
  mounted: function () {
    this.initialize();
  },
};
</script>
